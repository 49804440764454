import React, { useState, useEffect, useCallback } from 'react';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ZTitle from '../system/ZTitle';
import ZLoader from '../system/ZLoader';
import ZPagination from '../system/ZPagination';
import ZModalConfirm from '../system/ZModalConfirm';
import { Formatter } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ZFiltros from '../system/ZFiltros';
import ZMenuOptions from '../system/ZMenuOptions';
import clsx from 'clsx';
import { FaDownload } from 'react-icons/fa';
import FileDownload from 'js-file-download';

const useStyles = makeStyles(theme => ({
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	iconButton: {
		minWidth: 0,
	},
}));

export default props => {
	const history = useHistory();
	const { pathname } = history.location;
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimento = useSelector(store => store.admin.estabelecimento);
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});
	const classes = useStyles();

	// const [showExportMessage, setShowExportMessage] = useState(false);
	const [loading, setLoading] = useState(true);
	const [planos, setPlanos] = useState([]);
	const [removerPlano, setRemoverPlano] = useState({
		planoId: null,
		open: false,
	});
	const [filtros, setFiltros] = useState({
		id: '',
		nome: '',
		valor: 0,
		...filtersCache,
	});

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getPlanos = () => {
		setLoading(true);

		var params = {
			page,
			limit: rowsPerPage,
			id: Number(filtros.omni),
			nome: filtros.omni2,
			valor: filtros.valor ? parseInt(filtros.valor.replace(/[^\d]+/g, '')) / 100 : '',
		};

		if (estabelecimento) {
			params.estabelecimento = estabelecimento.id;
		}

		axios
			.get(`${config.get('apiUrl')}planos`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params,
			})
			.then(res => res.data)
			.then(data => {
				setLoading(false);
				if (data.success) {
					setPlanos(data.planos);
					setTotalRows(data.quantidade);
				}
			});
	};

	useEffect(() => {
		getPlanos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(getPlanos, [page, rowsPerPage, filtros, estabelecimento]);

	const handleFiltrosChange = useCallback(data => {
		setFiltros(filtros => ({ ...filtros, ...data }));
	}, []);

	const addPlano = () => {
		history.push('/planos/adicionar');
	};

	const handleRemoverPlano = result => {
		const planoId = removerPlano.planoId;
		setRemoverPlano({ ...removerPlano, open: false, planoId: null });

		if (result) {
			setLoading(true);
			axios
				.delete(`${config.get('apiUrl')}planos/${planoId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Plano removido com sucesso' }));
						getPlanos();
					}
					setLoading(false);
				});
		}
	};

	const exportXls = async () => {
		// setShowExportMessage(true);
		var params = {
			page,
			limit: rowsPerPage,
			id: Number(filtros.omni),
			nome: filtros.omni2,
			valor: filtros.valor ? parseInt(filtros.valor.replace(/[^\d]+/g, '')) / 100 : '',
		};

		if (estabelecimento) {
			params.estabelecimento = estabelecimento.id;
		}
		const planos = await axios.get(`${config.get('apiUrl')}export/planos`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params,
		});

		if (planos.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = planos.headers;
			let filename = 'planos.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(planos.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar planos.' }));
		}
		// setShowExportMessage(false);
	};
	return (
		<Grid container>
			<ZTitle title="Planos" button="add" handleOnClick={() => addPlano()} />
			<Grid item xs={12}>
				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ZFiltros
						onChange={handleFiltrosChange}
						input={[
							{
								key: 'omni',
								defaultValue: filtros.id,
								placeholder: 'id',
								modal: false,
								md: 2,
							},
							{
								key: 'omni2',
								placeholder: 'Titulo',
								modal: false,
								defaultValue: filtros.nome,
								md: 2,
							},
							{
								key: 'valor',
								placeholder: 'Valor',
								defaultValue: filtros.valor,
								mask: 'real',
								md: 2,
								modal: false,
							},
						]}
						// omni={{
						// 	default: filtros.id || '',
						// 	placeholder: 'id',
						// }}
						// omni2={{
						// 	default: filtros.nome || '',
						// 	placeholder: 'Titulo',
						// }}
						// valor={{
						// 	default: filtros.valor,
						// 	placeholder: 'Valor',
						// }}
					/>
					<Tooltip title="Exportar">
						<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXls}>
							<FaDownload className={classes.exportDownload} />
						</Button>
					</Tooltip>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				{loading ? (
					<ZLoader height="200px" size={40} />
				) : (
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)', marginTop: 32 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableCell>Titulo</TableCell>
									{(usuario.grupo_id === 1 || usuario.grupo_id === 2) && (
										<TableCell>Estabelecimento</TableCell>
									)}
									<TableCell>Assinantes</TableCell>
									<TableCell align="right">Preço</TableCell>
									<TableCell align="right">Setup</TableCell>
									<TableCell align="right">Frequência</TableCell>
									<TableCell align="right">Intervalo</TableCell>
									<TableCell align="right">Tolerância</TableCell>
									<TableCell align="right">Forma de Pagamento</TableCell>
									{/* <TableCell align="right">Status</TableCell> */}
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<>
									{planos.map((plano, key) => {
										let frequency = '';
										switch (plano.frequency) {
											case 'monthly':
												frequency = 'Mensal';
												break;
											case 'annually':
												frequency = 'Anual';
												break;
											case 'weekly':
												frequency = 'Semanal';
												break;
											case 'daily':
												frequency = 'Diaria';
												break;
											default:
												break;
										}

										let payment = '';
										switch (plano.method) {
											case 'credito':
											case 'credit':
												payment = 'Crédito';
												break;
											case 'boleto':
												payment = 'Boleto';
												break;
											case 'pix':
												payment = 'Pix';
												break;
											default:
												break;
										}
										return (
											<TableRow hover key={key}>
												<TableCell>{plano.id}</TableCell>
												<TableCell component="th" scope="row">
													{plano.name}
												</TableCell>
												{(usuario.grupo_id === 1 || usuario.grupo_id === 2) && (
													<TableCell component="th" scope="row">
														{plano.estabelecimento.nome_fantasia}
													</TableCell>
												)}
												<TableCell component="th" align="right" scope="row">
													{plano.assinantes}
												</TableCell>
												<TableCell align="right">
													{Formatter.Real(plano.amount / 100)}
												</TableCell>
												<TableCell align="right">
													{Formatter.Real(plano.setup_amount / 100)}
												</TableCell>
												<TableCell align="right">{frequency}</TableCell>
												<TableCell align="right">{plano.interval}</TableCell>
												<TableCell align="right">
													{plano.tolerance_period}{' '}
													{parseInt(plano.tolerance_period) > 1 ? 'dias' : 'dia'}
												</TableCell>
												<TableCell align="right">{payment}</TableCell>
												{/* <TableCell align="right">{plano.ativo === true ? 'Ativo' : 'Desativado'}</TableCell> */}
												<TableCell align="right">
													<ZMenuOptions
														data={[
															{
																title: 'Editar',
																onClick: () => {
																	history.push('/planos/editar/' + plano.id);
																},
															},
															{
																title: 'Detalhes do Plano',
																onClick: () => {
																	history.push('/planos/detalhe/' + plano.id);
																},
															},
															{
																title: 'Assinaturas',
																onClick: () => {
																	history.push('/assinaturas/' + plano.id);
																},
															},
															{
																title: 'URL para assinar',
																onClick: () => {
																	window.open(
																		config.get('baseName') +
																			'/plano/' +
																			plano.id +
																			'/assinar'
																	);
																}, // history.push('/plano/'+plano.id+'/assinar')}
															},
															{
																title: 'Remover',
																onClick: () =>
																	setRemoverPlano({
																		...removerPlano,
																		planoId: plano.id,
																		open: true,
																	}),
															},
														]}
													/>
												</TableCell>
											</TableRow>
										);
									})}
								</>
							</TableBody>
							<ZPagination
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					</Paper>
				)}
			</Grid>
			<ZModalConfirm
				title={`Remover Plano`}
				message={`Excluir o plano não desabilita os assinantes. Se esse plano tiver assinantes ativos a cobrança da assinatura ocorrerá normalmente.
				Deseja prosseguir com a exclusão do plano?`}
				open={removerPlano.open}
				onClose={handleRemoverPlano}
			/>
		</Grid>
	);
};
