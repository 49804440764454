import React, { useState, useEffect, memo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';

import axios from 'axios';
import config from 'react-global-configuration';

import format from 'date-fns/format';
import pt from 'date-fns/locale/pt';
import { Formatter, Isset } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { FaBarcode, FaCheckCircle, FaTimesCircle, FaExclamationCircle, FaDollarSign } from 'react-icons/fa';

import ZLoader from '../../system/ZLoader';
import { useHistory } from 'react-router-dom';
import pix from '../../../assets/flags/pix.png';
import generic from '../../../assets/flags/cartao-de-credito.png';

//import das bandeiras
const mastercard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279472056_mastercard.png';
const visa = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279837385_visa-logo.png';
const elo = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279880875_elo-logo.png';
const visaElectron = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279888851_visa-electron.png';
const american = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279872449_american.png';
const diners = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279879091_dinners.png';
const hiper = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279883385_hiper-logo.png';
const hipercard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279884022_hipercard-logo.png';
const maestrologo = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279886717_maestro-logo.png';
const banescard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279875231_banescard-logo.png';
const Bolepix = "https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1719522370149_Bolepix.png";

const TooltipTitle = props => {
	return (
		<div>
			<div>{props.type}</div>
			<div>{props.brand}</div>
		</div>
	);
};

const getBandeira = bandeira => {
	switch (bandeira) {
		case 'american express':
			return 'American Express';
		case 'elo':
			return 'Elo';
		case 'maestro':
			return 'Maestro';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'visa electron':
			return 'Visa Electron';
		case 'diners club':
			return 'Diners Club';
		case 'hiper':
			return 'Hiper';
		case 'hipercard':
			return 'HiperCard';
		case 'banescard':
			return 'Banescard';
		default:
			return bandeira;
	}
};

const getPaymentIcon = (paymentType, brand, className = null) => {
	brand = getBandeira(brand);
	if (paymentType === 'boleto') {
		return (
			<Tooltip title="Boleto Bancário">
				<div>
					<FaBarcode size={25} />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 'bolepix') {
		return (
			<Tooltip title={<TooltipTitle type="Bolepix" brand={brand} />}>
				<div>
					<img alt="" height={10} src={Bolepix} />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 'debit') {
		switch (brand) {
			case 'Maestro':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={maestrologo} />
						</div>
					</Tooltip>
				);
			case 'Mastercard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={mastercard} />
						</div>
					</Tooltip>
				);
			case 'Visa Electron':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={20} src={visaElectron} />
						</div>
					</Tooltip>
				);
			case 'Visa':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={20} src={visa} />
						</div>
					</Tooltip>
				);
			case 'Elo':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={elo} />
						</div>
					</Tooltip>
				);
			case 'Diners Club':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={diners} />
						</div>
					</Tooltip>
				);
			case 'HiperCard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={hipercard} />
						</div>
					</Tooltip>
				);
			case 'Hiper':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={hiper} />
						</div>
					</Tooltip>
				);
			case 'Banescard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={banescard} />
						</div>
					</Tooltip>
				);
			default:
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={generic} />
						</div>
					</Tooltip>
				);
		}
	}

	if (paymentType === 'credit') {
		switch (brand) {
			case 'Mastercard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={mastercard} />
						</div>
					</Tooltip>
				);
			case 'Visa':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={visa} />
						</div>
					</Tooltip>
				);
			case 'American Express':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={american} />
						</div>
					</Tooltip>
				);
			case 'Elo':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={elo} />
						</div>
					</Tooltip>
				);
			case 'Diners Club':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={diners} />
						</div>
					</Tooltip>
				);
			case 'HiperCard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={hipercard} />
						</div>
					</Tooltip>
				);
			case 'Hiper':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={hiper} />
						</div>
					</Tooltip>
				);
			case 'Banescard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={banescard} />
						</div>
					</Tooltip>
				);
			default:
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<img alt="" height={25} src={generic} />
						</div>
					</Tooltip>
				);
		}
	}

	if (paymentType === 4) {
		return (
			<Tooltip title="Comissão">
				<div>
					<FaDollarSign />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 'pix') {
		return (
			<Tooltip title={'PIX'}>
				<div>
					<img alt="" height={25} src={pix} />
				</div>
			</Tooltip>
		);
	}
};

const getStatusIcon = statusId => {
	let title = '';
	let icon = '';
	switch (+statusId) {
		case 2: {
			title = 'Aprovado';
			icon = <FaCheckCircle style={{ color: green[500] }} />;
			break;
		}
		case 14: {
			title = 'Aprovado';
			icon = <FaCheckCircle style={{ color: green[500] }} />;
			break;
		}
		case 3: {
			title = 'Falhado';
			icon = <FaTimesCircle style={{ color: red[700] }} />;
			break;
		}
		case 1:
		default: {
			title = 'Pendente';
			icon = <FaExclamationCircle style={{ color: yellow[700] }} />;
		}
	}

	return (
		<Tooltip title={title} placement="bottom">
			<div>{icon}</div>
		</Tooltip>
	);
};

// const getPaymentIcon = (paymentType, brand) => {
// 	if (paymentType === 1) {
// 		return <FaBarcode />;
// 	}

// 	if (paymentType === 2) {
// 		return <FaRegCreditCard />;
// 	}

// 	if (paymentType === 3) {
// 		switch (brand) {
// 			case 'mastercard':
// 				return <FaCcMastercard />;
// 			case 'visa':
// 				return <FaCcVisa />;
// 			case 'amex':
// 				return <FaCcAmex />;
// 			default:
// 				return <FaCreditCard />;
// 		}
// 	}

// 	if (paymentType === 4) {
// 		return <FaDollarSign />;
// 	}

// 	if (paymentType === 6) {
// 		return (
// 			<div style={{ width: 20, height: 20, margin: 'auto', marginTop: -8 }}>
// 				<PixIcon />
// 			</div>
// 		);
// 	}
// };

const useStyles = makeStyles(theme => ({
	dashboard: {
		padding: 10,
	},
	box: {
		position: 'relative',
		padding: 10,
		height: '100%',
	},
	boxTitle: {
		fontWeight: 700,
		fontSize: 14,
		margin: 4,
	},
	boxFiltro: {
		position: 'absolute',
		top: 10,
		right: 5,
	},
	extrato: {
		height: 270,
	},
	extratoChart: {
		width: '100%',
		height: 160,
	},
	lancamentosChart: {
		width: '100%',
		height: 230,
	},
	extratoIO: {
		marginTop: 10,
		marginBottom: 20,
	},
	extratoFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	progress: {
		height: 6,
		borderRadius: 6,
	},
	progressEntrada: {
		backgroundColor: `#ea606187`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#ea6061FF`,
		},
	},
	progressSaida: {
		backgroundColor: `#00579787`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#005797FF`,
		},
	},
	vendas: {
		minHeight: 435,
	},
	btnUltimasVendas: {
		[theme.breakpoints.down('md')]: {
			marginTop: 35,
		},
	},
	vendasChart: {
		width: '100%',
		height: 280,
		marginBottom: 10,
		[theme.breakpoints.up('md')]: {
			width: 300,
		},
	},
	webOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	gridHeader: {
		fontWeight: 700,
		color: 'rgba(0,0,0,0.4)',
		height: 30,
	},
	ultimasVendas: {
		maxHeight: 300,
		overflowY: 'auto',
	},
	ultimasVendasFooter: {
		// position: 'absolute',
		bottom: 4,
		marginTop: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	ultimasTransferencias: {
		maxHeight: 210,
		overflowY: 'auto',
		marginBottom: 25,
	},
	ultimasTransferenciasFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}67`,
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tipoPagamentoIcon: {
		color: theme.palette.primary.main,
		fontSize: 20,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
	},
	extratoEmpty: {
		height: 120,
	},
	vendasEmpty: {
		height: '100%',
	},
	lancamentosEmpty: {
		height: 230,
	},
	transferenciasEmpty: {
		height: '100%',
	},
	transferenciaDe: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	transferenciaPara: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		textAlign: 'left',
		overflow: 'hidden',
	},
	hide: {
		display: 'none !important',
	},
}));

export default memo(props => {
	const history = useHistory();

	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);

	const canViewEC = [1, 2, 4, 7, 10, 11, 12, 13, 15].includes(usuario.grupo_id);

	const [loading, setLoading] = useState(true);
	const [ultimasVendas, setUltimasVendas] = useState([]);

	useEffect(() => {
		const getUltimasVendas = async () => {
			setLoading(true);
			const vendas = await axios.get(`${config.get('apiUrl')}vendas/lista`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					limit: 20,
					filters: {
						estabelecimentoId: props.estabelecimentoId,
						status: [1, 2, 14],
					},
				},
			});

			if (vendas.data.success) {
				const result = [];
				let lastDate = null;
				vendas.data.vendas.forEach(v => {
					const date = format(v.created, 'YYYY-MM-DD');
					if (lastDate !== date) {
						lastDate = date;
						result.push({ type: 'date', date });
					}
					result.push({
						type: 'row',
						id: v.id,
						created: v.created,
						statusId: v.status_pedido_id,
						tipoPagamentoId: Isset(() => v.tipo_pagamento) ? v.tipo_pagamento : null,
						bandeira: v.bandeira,
						valor: v.valor_bruto,
						estabelecimento: v.estabelecimento.nome_fantasia,
					});
				});
				setUltimasVendas(result);
			}
			setLoading(false);
		};

		getUltimasVendas();
	}, [props.estabelecimentoId, token]);

	const viewVenda = vendaId => history.push(`/vendas/detalhes/${vendaId}`);

	return (
		<div>
			<div className={classes.gridHeader}>Últimas Vendas</div>
			{loading ? (
				<ZLoader height={200} size={50} />
			) : (
				<>
					{ultimasVendas.length > 0 ? (
						<Grid container className={classes.ultimasVendas}>
							{ultimasVendas.map((venda, key) => (
								<React.Fragment key={key}>
									{venda.type === 'date' ? (
										<Grid container item className={classes.tableDateRow}>
											<Grid item xs>
												{format(venda.date, 'DD [de] MMMM [de] YYYY', {
													locale: pt,
												})}
											</Grid>
										</Grid>
									) : (
										<Grid
											container
											item
											className={classes.tableRow}
											onClick={() => viewVenda(venda.id)}
										>
											<Grid item xs={2}>
												{format(venda.created, 'HH:mm', { locale: pt })}
											</Grid>
											<Grid item xs={1}>
												{getStatusIcon(venda.statusId)}
											</Grid>
											{canViewEC && (
												<Grid
													item
													xs
													style={{
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														overflow: 'hidden',
													}}
												>
													{venda.estabelecimento.slice(0, 20)}
												</Grid>
											)}
											<Grid item xs>
												{Formatter.Real(venda.valor)}
											</Grid>
											<Grid item xs={1} className={classes.tipoPagamentoIcon}>
												{getPaymentIcon(venda.tipoPagamentoId, venda.bandeira)}
											</Grid>
										</Grid>
									)}
								</React.Fragment>
							))}
						</Grid>
					) : (
						<div className={clsx(classes.vendasEmpty, classes.empty)}>Nenhuma Venda Encontrada.</div>
					)}
				</>
			)}
		</div>
	);
});
