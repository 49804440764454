/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Formatter, Mask, Isset } from '../../utils';

import ZLoader from '../system/ZLoader';

import Grid from '@material-ui/core/Grid';
import CalculadoraValorLiquido from '../Calculadoras/ValorLiquido';
import CalculadoraValorBruto from '../Calculadoras/ValorBruto';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { FaCreditCard } from 'react-icons/fa';

//import das bandeiras

const mastercard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279472056_mastercard.png';
const visa = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279837385_visa-logo.png';
const elo = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279880875_elo-logo.png';
const american = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279872449_american.png';
const diners = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279879091_dinners.png';
const hiper = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279883385_hiper-logo.png';
const hipercard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279884022_hipercard-logo.png';
const banescard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279875231_banescard-logo.png';
const aura = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279873417_aura-logo.png';
const cabal = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279876619_cabal-logo.png';
const discover = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279879904_discover.png';
const jcb = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279885741_jcb-logo.png';

const useStyles = makeStyles(theme => ({
	taxasWrapper: {
		minHeight: 740,
	},
	taxasWrapper21: {
		minHeight: 1000,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	pageTitle: {
		fontWeight: 'bold',
		fontSize: 18,
		marginTop: 20,
		marginBottom: 10,
		paddingLeft: 20,
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	planos: {
		position: 'relative',
		padding: 14,
		height: 50,
		fontSize: 14,
		fontWeight: 600,
		'& span': {
			fontWeight: 'bold',
			fontSize: 16,
		},
		[theme.breakpoints.down('xs')]: {
			paddingBottom: 52,
		},
	},
	taxaBoletoDiv: {
		marginTop: 10,
		position: 'relative',
		padding: 10,
		height: 50,
		display: 'flex',
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoBoleto: {
		float: 'left',
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
	},
	tipoPagamentoPix: {
		float: 'left',
		marginLeft: 20,
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
	},
	bandeirasWrapper: {
		position: 'absolute',
		marginLeft: 120,
		width: 'calc(100% - 160px)',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
		display: 'flex',
		justifyContent: 'center',
	},
	titleLong: {
		fontSize: 11,
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 7.45,
		'&::after': {
			content: '"%"',
			position: 'absolute',
			right: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		// marginTop: 5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldBoletoReadOnly: {
		marginLeft: 120,
		height: 30.8,
		fontWeight: 'bold',
		fontSize: 15,
		marginTop: 5,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		marginTop: 1,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	btnCalculadora: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
		marginRight: 10,
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
}));

export default props => {
	let { estabelecimentoId } = useParams();

	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}
	let { tipoVendaId } = props;

	if (!tipoVendaId) {
		tipoVendaId = 1;
	}

	const token = useSelector(store => store.auth.token);
	const userGrupoId = useSelector(store => store.auth.usuario.grupo_id);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const classes = useStyles();

	const [estabelecimentoNome, setEstabelecimentoNome] = useState('');
	const [planoVenda, setPlanoVenda] = useState({});
	const [planoTaxa, setPlanoTaxa] = useState({});
	const [taxaBoleto, setTaxaBoleto] = useState(0);

	const [taxaPix, setTaxaPix] = useState(0);
	const [typeFeePix, setTypeFeePix] = useState('percent');
	const [taxas, setTaxas] = useState({});
	const [countBandeiras, setCountBandeiras] = useState(1);
	const [loading, setLoading] = useState(true);
	const [erroTaxa, setErroTaxa] = useState(false);
	const [maxParcela, setMaxParcela] = useState(new Array(12).fill(''));

	const [showCalculadoraValorLiquido, setShowCalculadoraValorLiquido] = useState(false);
	const [showCalculadoraValorBruto, setShowCalculadoraValorBruto] = useState(false);

	const [hasOnline, setHasOnline] = useState(false);

	const canViewPlanos = userGrupoId === 1 || operarMarketplace;

	useEffect(() => {
		const getTaxas = async () => {
			setLoading(true);
			setErroTaxa(false);
			const res = await Axios.get(
				`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${estabelecimentoId}/taxaTotal`,
				{
					params: { tipoVendaId },
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setPlanoVenda(res.data.planoVenda);
				setPlanoTaxa(res.data.planoTaxa);
				setTaxaBoleto(res.data.taxaBoleto);
				setTaxaPix(res.data.taxaPix);
				if (typeFeePix !== res.data.typeFeePix) {
					setTypeFeePix(res.data.typeFeePix);
				}
				setTaxas(res.data.taxaTotal);
				setCountBandeiras(Object.keys(res.data.taxaTotal).length);
				setHasOnline(res.data.hasOnline)
			} else {
				setErroTaxa(true);
			}
			setLoading(false);
		};

		const getEC = async () => {
			const res = await Axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
                query Estabelecimentos {
                    estabelecimento(id: ${estabelecimentoId}) {
                        nomeFantasia
                    }
                }
            `,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.estabelecimento)) {
				const { nomeFantasia: nome } = res.data.data.estabelecimento;
				setEstabelecimentoNome(nome);
			}
		};

		getTaxas();
		getEC();
	}, []);

	useEffect(() => {
		const setParcelas = async () => {
			let parcelaMaxima = 12;
			if (Object.keys(taxas).length) {
				Object.keys(taxas).map((v, i) => {
					const currentMaxParcel = Math.max(...Object.keys(taxas[v].valores.credit.valores));
					if (currentMaxParcel > parcelaMaxima) {
						parcelaMaxima = currentMaxParcel;
						i = 1;
						const parcelas = [];
						while (i <= parcelaMaxima) {
							parcelas.push(i);
							i++;
						}

						setMaxParcela(parcelas);
					}
					return null;
				});
			}
		};
		setParcelas();
	}, [taxas]);

	if (erroTaxa) {
		return (
			<div>
				<div className={classes.header}>Taxas - {estabelecimentoNome}</div>
				<div className={classes.empty}>Nenhuma Taxa Encontrada.</div>
			</div>
		);
	}

	// if (tipoVendaId === 2 && !planoVenda.antecipado) {
	if (tipoVendaId === 2 && !hasOnline) {
		return null;
	}

	const TooltipTitle = props => {
		return (
			<div>
				<div>{props.type}</div>
				<div>{props.brand}</div>
			</div>
		);
	};

	const getPaymentIcon = brand => {
		switch (brand) {
			case 'Mastercard':
			case 'MasterCard':
			case 'Maestro':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={mastercard} />
						</div>
					</Tooltip>
				);
			case 'Visa':
			case 'Visa Electron':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={visa} />
						</div>
					</Tooltip>
				);
			case 'American Express':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={american} />
						</div>
					</Tooltip>
				);
			case 'Elo':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={elo} />
						</div>
					</Tooltip>
				);
			case 'Diners Club':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={diners} />
						</div>
					</Tooltip>
				);
			case 'Hipercard':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={hipercard} />
						</div>
					</Tooltip>
				);
			case 'Hiper':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={hiper} />
						</div>
					</Tooltip>
				);
			case 'Banescard':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={banescard} />
						</div>
					</Tooltip>
				);
			case 'Aura':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={aura} />
						</div>
					</Tooltip>
				);
			case 'Cabal':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={cabal} />
						</div>
					</Tooltip>
				);
			case 'Discover':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={discover} />
						</div>
					</Tooltip>
				);
			case 'JCB':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={jcb} />
						</div>
					</Tooltip>
				);
			default:
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div>
							<FaCreditCard />
						</div>
					</Tooltip>
				);
		}
	};

	return (
		<div className={maxParcela.length === 12 ? classes.taxasWrapper : classes.taxasWrapper21}>
			{tipoVendaId === 1 && (
				<>
					<Button className={classes.btnCalculadora} onClick={() => setShowCalculadoraValorLiquido(true)}>
						<div>
							<small>Calculadora</small>
						</div>
						<div>Valor Líquido</div>
					</Button>
					<Button className={classes.btnCalculadora} onClick={() => setShowCalculadoraValorBruto(true)}>
						<div>
							<small>Calculadora</small>
						</div>
						<div>Valor Bruto</div>
					</Button>
					<div className={classes.header}>Taxas - {estabelecimentoNome}</div>
					{canViewPlanos && (
						<div className={clsx(classes.tiposPagamentos, classes.planos)}>
							<Grid container spacing={2}>
								<Grid item sm={4} xs={6}>
									Plano de Venda: <span>{planoVenda.titulo}</span>
								</Grid>
								<Grid item sm={4} xs={6}>
									Tabela de Markup: <span>{planoTaxa.titulo ? planoTaxa.titulo : 'Nenhum'}</span>
								</Grid>
							</Grid>
						</div>
					)}
				</>
			)}
			{tipoVendaId === 2 && <div className={classes.pageTitle}>Tabela Vendas Online</div>}

			{tipoVendaId === 1 && (
				<div className={classes.taxaBoleto}>
					<div className={clsx(classes.tiposPagamentos, classes.taxaBoletoDiv)}>
						<div>
							<div className={classes.tipoPagamentoBoleto}>Boleto</div>
							<div className={classes.taxaFieldBoletoReadOnly}>{Formatter.Real(taxaBoleto)}</div>
						</div>
						<div>
							<div className={classes.tipoPagamentoPix}>Pix</div>
							<div className={classes.taxaFieldBoletoReadOnly}>
								{typeFeePix === 'fixed' && <>R$ </>}

								{taxaPix}

								{typeFeePix === 'percent' && <>%</>}
							</div>
						</div>
					</div>
				</div>
			)}
			<div style={{ marginTop: 10 }}>
				<div className={classes.tiposPagamentos}>
					{maxParcela.map((v, i) => {
						return (
							<div key={i} className={classes.tipoPagamento}>
								Crédito {++i}x
							</div>
						);
					})}
					<div className={classes.tipoPagamento}>Débito</div>
				</div>

				{loading ? (
					<ZLoader height={300} />
				) : (
					<div className={classes.bandeirasWrapper}>
						<div style={{ width: (countBandeiras + 1) * 110 }}>
							{Object.keys(taxas).map(bandeiraId => (
								<div key={`Bandeira-${bandeiraId}`} className={classes.bandeira}>
									<div
										className={clsx(classes.title, {
											[classes.titleLong]:
												Isset(() => taxas[bandeiraId].titulo.length) &&
												taxas[bandeiraId].titulo.length > 10,
										})}
									>
										{/* {taxas[bandeiraId].titulo} */}
										{getPaymentIcon(taxas[bandeiraId].titulo)}
									</div>
									<div className={classes.fields}>
										{Object.keys(taxas[bandeiraId].valores).map(tipoPagamentoId => (
											<div
												key={`${bandeiraId}${tipoPagamentoId}`}
												className={classes.tipoPagamentoFields}
											>
												{Object.keys(taxas[bandeiraId].valores[tipoPagamentoId].valores).map(
													parcela => (
														<div
															key={`${bandeiraId}-${parcela}`}
															className={classes.taxaFieldReadOnly}
														>
															{Mask.Porcentagem(
																taxas[bandeiraId].valores[tipoPagamentoId].valores[
																parcela
																]
															)}{' '}
															%
														</div>
													)
												)}
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>

			{tipoVendaId === 1 && (
				<>
					{showCalculadoraValorLiquido && (
						<CalculadoraValorLiquido
							open={showCalculadoraValorLiquido}
							estabelecimentoId={estabelecimentoId}
							onClose={() => setShowCalculadoraValorLiquido(false)}
						/>
					)}

					{showCalculadoraValorBruto && (
						<CalculadoraValorBruto
							open={showCalculadoraValorBruto}
							estabelecimentoId={estabelecimentoId}
							onClose={() => setShowCalculadoraValorBruto(false)}
						/>
					)}
				</>
			)}
		</div>
	);
};
