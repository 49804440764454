import React, { useEffect, useMemo, useState } from 'react';
import { Paper, Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import format from 'date-fns/format';

import { Mask, Isset } from '../../utils';
import ZTitle from '../system/ZTitle';
import ZLoader from '../system/ZLoader';
import { useCallback } from 'react';
import ZModalConfirm from '../system/ZModalConfirm';
import { addMessage } from '../../store/alert';

const styles = makeStyles(theme => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 960,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	text: {
		fontSize: 16,
		color: '#777',
	},
	label: {
		// fontWeight: 700,
		color: '#777',
		margin: 0,
	},
}));

export default () => {
	const classes = styles();

	const history = useHistory();
	const { estabelecimentoId } = useParams();
	const token = useSelector(store => store.auth.token);
	const dispatch = useDispatch();
	const [cancelRetroativo, setCancelRetroativo] = React.useState(false);

	const [loading, setLoading] = useState(true);
	const [estabelecimento, setEstabelecimento] = useState({
		nome: '',
		documento: '',
	});
	const [cobranca, setCobranca] = useState([]);
	const [isCobranca, setIsCobranca] = useState(true);

	const [deletarCobranca, setDeletarCobranca] = useState({
		cobrancaId: null,
		open: false,
	});
	const handleCancelRetroativoChange = event => {
		console.log(event.target.checked);
		setCancelRetroativo(event.target.checked);
	};
	useEffect(() => {
		getEC();

		getCobranca();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getEC = useCallback(async () => {
		const response = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.data.success && response.data.estabelecimento) {
			const {
				nome_fantasia: nome,
				estabelecimentos_documentos: [{ documento }],
			} = response.data.estabelecimento;

			setEstabelecimento({ nome, documento });
		}
	}, [estabelecimentoId, token]);

	const getCobranca = useCallback(async () => {
		const response = await axios.get(`${config.get('apiUrl')}cobranca_recorrente`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				estabelecimentoId,
			},
		});

		if (response.data.success && response.data.cobrancas) {
			const { cobrancas } = response.data;

			setCobranca(cobrancas);
			setLoading(false);
		}
	}, [token, estabelecimentoId]);

	const handleRemoverCobranca = useCallback(
		result => {
			const { cobrancaId } = deletarCobranca;

			if (result) {
				console.log(cancelRetroativo);

				axios
					.delete(`${config.get('apiUrl')}cobranca_recorrente/${cobrancaId}?retroativo=${cancelRetroativo}`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(response => response.data)
					.then(data => {
						if (data.success) {
							dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso ' }));

							getCobranca();
						} else {
							dispatch(addMessage({ type: 'error', message: data.error }));
						}

						setDeletarCobranca({ ...deletarCobranca, open: false, cobrancaId: null });
					});
			}
		},
		[deletarCobranca, dispatch, token, getCobranca, cancelRetroativo]
	);

	const cpfOuCnpj = useMemo(() => {
		if (Isset(() => estabelecimento.documento) && estabelecimento.documento) {
			return estabelecimento.documento.length <= 11
				? Mask.Cpf(estabelecimento.documento)
				: Mask.Cnpj(estabelecimento.documento);
		}

		return '';
	}, [estabelecimento]);

	const temCobranca = useMemo(() => {
		return typeof cobranca !== 'undefined' && cobranca.length > 0;
	}, [cobranca]);

	const tipoCobranca = useMemo(() => {
		return cobranca.map(cobranca => {
			const tipoCobranca = cobranca.tipo_cobranca_recorrente_id;

			if (tipoCobranca === 1) {
				return 'Aluguel de Máquina';
			} else if (tipoCobranca === 2) {
				return 'Cobrança por Transação';
			} else if (tipoCobranca === 3) {
				return 'Pacote Financeiro';
			} else if (tipoCobranca === 4) {
				return 'Taxa Administrativa';
			} else {
				return 'Taxa Transferência P2P';
			}
		});
	}, [cobranca]);

	if (loading) {
		return <ZLoader height="200px" size={40} />;
	}

	return (
		<>
			<div className={classes.layout}>
				<ZTitle title="Cadastrar Taxa" />

				<Paper className={classes.paper}>
					<Typography variant="h5" gutterBottom>
						{estabelecimento.nome} <br /> <span className={classes.text}>{cpfOuCnpj}</span>
					</Typography>

					{temCobranca ? (
						<Grid container style={{ marginTop: 32 }}>
							{cobranca.map((cobranca, key) => (
								<Grid key={cobranca.id} item xs={12} md={4} lg={4}>
									<Grid container item xs={12} direction="column" justifyContent="space-around">
										<Typography variant="h6" gutterBottom>
											{tipoCobranca[key]}
										</Typography>
									</Grid>

									<Grid container>
										<Grid item xs={12}>
											<p className={classes.label}>
												Recorrência: <span> todo dia {cobranca.day} </span>
											</p>
										</Grid>

										<Grid xs={12}>
											<p className={classes.label}>
												Data inicial da cobrança:{' '}
												<span> {format(cobranca.data_inicial, 'DD/MM/YYYY')} </span>
											</p>
										</Grid>

										{cobranca.valor ? (
											<Grid>
												<p className={classes.label}>
													Valor: <span> {Mask.Real(cobranca.valor / 100)} </span>
												</p>
											</Grid>
										) : (
											<>
												<br />
												<br />
											</>
										)}

										{cobranca.meta ? (
											<Grid xs={12}>
												<p className={classes.label}>
													Faturamento mínimo: <span> {Mask.Real(cobranca.meta / 100)} </span>
												</p>
											</Grid>
										) : (
											<>
												<br />
												<br />
											</>
										)}

										{cobranca.meta_batida ? (
											<Grid xs={12}>
												<p className={classes.label}>
													Atingiu faturamento:{' '}
													<span> {Mask.Real(cobranca.meta_batida / 100)} </span>
												</p>
											</Grid>
										) : (
											<>
												<br />
											</>
										)}

										{cobranca.meta_nao_batida ? (
											<Grid xs={12}>
												<p className={classes.label}>
													Não atingiu faturamento:{' '}
													<span> {Mask.Real(cobranca.meta_nao_batida / 100)} </span>
												</p>
											</Grid>
										) : (
											<br />
										)}

										<Grid
											container
											style={{ marginTop: 24, marginBottom: 40 }}
											alignItems="flex-end"
										>
											<Grid item xs={6}>
												<Button
													variant="contained"
													color="primary"
													size="small"
													onClick={() =>
														history.push(
															`/estabelecimentos/cobranca_recorrente/${cobranca.id}/editar`
														)
													}
												>
													Editar
												</Button>
											</Grid>
											<Grid item xs={6}>
												<Button
													variant="contained"
													size="small"
													style={{ backgroundColor: '#D34242', color: '#FFF' }}
													onClick={() =>
														setDeletarCobranca({
															...deletarCobranca,
															cobrancaId: cobranca.id,
															open: true,
														})
													}
												>
													Deletar
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							))}
						</Grid>
					) : (
						<Grid
							container
							item
							xs={12}
							justifyContent="center"
							style={{ marginTop: 48, marginBottom: 48 }}
						>
							<Typography variant="body1" gutterBottom>
								Se você deseja adicionar uma nova cobrança clique no botão abaixo
							</Typography>
						</Grid>
					)}

					<Grid container justifyContent="flex-end" style={{ marginTop: 48 }}>
						<Grid item xs={12} sm={12} md={6} lg={3}>
							<Button
								onClick={() =>
									history.push(`/estabelecimentos/cobranca_recorrente/${estabelecimentoId}/adicionar`)
								}
								variant="contained"
								color="primary"
								fullWidth
							>
								Adicionar
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>

			<ZModalConfirm
				title={`Deletar cobrança`}
				message={`Deseja deletar essa cobrança?`}
				open={deletarCobranca.open}
				onClose={handleRemoverCobranca}
				isCobranca={isCobranca}
				handleCancelRetroativoChange={handleCancelRetroativoChange}
				cancelRetroativo={cancelRetroativo}
			/>
		</>
	);
};
