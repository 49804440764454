/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ZLoader from '../system/ZLoader';

import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import pt from 'date-fns/locale/pt';
import queryString from 'query-string';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LastExport from '../Export/LastExport';
import ZModalConfirm from '../system/ZModalConfirm';

// import moment from 'moment';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import ZEmpty from '../system/ZEmpty';
import ZFiltros from '../system/ZFiltros';
import ZPaginationDiv from '../system/ZPaginationDiv';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';

import {
	FaHistory,
	FaBarcode,
	FaCreditCard,
	FaCheckCircle,
	FaTimesCircle,
	FaMinusCircle,
	FaExclamationCircle,
	FaBandcamp,
	FaRegCreditCard,
	FaDollarSign,
	FaTimes,
	FaStopCircle,
	FaDownload,
} from 'react-icons/fa';
import PixIcon from '../../assets/images/Pix';
import { FiRefreshCw } from 'react-icons/fi';

import { addMessage } from '../../store/alert';

//import das bandeiras
const mastercard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279472056_mastercard.png';
const visa = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279837385_visa-logo.png';
const elo = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279880875_elo-logo.png';
const visaElectron = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279888851_visa-electron.png';
const american = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279872449_american.png';
const diners = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279879091_dinners.png';
const hiper = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279883385_hiper-logo.png';
const hipercard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279884022_hipercard-logo.png';
const maestrologo = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279886717_maestro-logo.png';
const banescard = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1715279875231_banescard-logo.png';
const Bolepix = 'https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1719522370149_Bolepix.png';

const getStatusIcon = statusId => {
	switch (+statusId) {
		case 2:
			return (
				<Tooltip title="Aprovada">
					<div>
						<FaCheckCircle style={{ color: green[500] }} />
					</div>
				</Tooltip>
			);
		case 14:
			return (
				<Tooltip title="Aprovada">
					<div>
						<FaCheckCircle style={{ color: green[500] }} />
					</div>
				</Tooltip>
			);
		case 3:
			return (
				<Tooltip title="Falhado">
					<div>
						<FaStopCircle style={{ color: red[400] }} />
					</div>
				</Tooltip>
			);
		case 4:
			return (
				<Tooltip title="Cancelada">
					<div>
						<FaTimesCircle style={{ color: red[700] }} />
					</div>
				</Tooltip>
			);
		case 6:
			return (
				<Tooltip title="Estornado">
					<div>
						<FaTimesCircle style={{ color: orange[700] }} />
					</div>
				</Tooltip>
			);
		case 10:
			return (
				<Tooltip title="Reverso">
					<div>
						<FaExclamationCircle style={{ color: yellow[700] }} />
					</div>
				</Tooltip>
			);
		case 13:
			return (
				<Tooltip title="Charged-Back">
					<div>
						<FaTimesCircle style={{ color: orange[700] }} />
					</div>
				</Tooltip>
			);
		case 11:
			return (
				<Tooltip title="Review Anti-Fraude">
					<div>
						<FaBandcamp style={{ color: orange[300] }} />
					</div>
				</Tooltip>
			);
		case 12:
			return (
				<Tooltip title="Recusado Anti-Fraude">
					<div>
						<FaMinusCircle style={{ color: red[400] }} />
					</div>
				</Tooltip>
			);
		case 16:
			return (
				<Tooltip title="Pré-Cancelado">
					<div>
						<FaBandcamp style={{ color: orange[300] }} />
					</div>
				</Tooltip>
			);
		case 17:
			return (
				<Tooltip title="Transferido">
					<div>
						<FaTimesCircle style={{ color: orange[700] }} />
					</div>
				</Tooltip>
			);
		case 1:
		default:
			return (
				<Tooltip title="Pendente">
					<div>
						<FaExclamationCircle style={{ color: yellow[700] }} />
					</div>
				</Tooltip>
			);
	}
};

const TooltipTitle = props => {
	return (
		<div>
			<div>{props.type}</div>
			<div>{props.brand}</div>
		</div>
	);
};

const getPaymentIndicator = paymentType => {
	switch (paymentType) {
		case 1:
			return 'B';
		case 2:
			return 'D';
		case 3:
			return 'C';
		default:
			return '';
	}
};

const getOptions = () => {
	const result = {
		statusVenda: [
			{ id: '1', name: 'Pendente' },
			{ id: '2', name: 'Aprovado' },
			{ id: '3', name: 'Falhado' },
			{ id: '4', name: 'Cancelado' },
			{ id: '5', name: 'Parcialmente Pago' },
			{ id: '6', name: 'Estornado' },
			{ id: '7', name: 'Em Processamento' },
			{ id: '8', name: 'Pré Autorizado' },
			{ id: '9', name: 'Disputa' },
			{ id: '10', name: 'Reverso' },
			{ id: '12', name: 'Recusado Anti Fraude' },
			{ id: '13', name: 'Charge Back' },
			{ id: '14', name: 'Pago via cartão de crédito' },
		],
		formaPagamento: [
			{ id: 'boleto', name: 'Boleto' },
			{ id: 'bolepix', name: 'Bolepix' },
			{ id: 'debito', name: 'Débito' },
			{ id: 'creditoVista', name: 'Crédito à Vista' },
			{ id: 'creditoParcelado', name: 'Crédito Parcelado' },
			{ id: 'pix', name: 'Pix' },
		],
		tipoVenda: [
			{ id: '1', name: 'Presencial' },
			{ id: '2', name: 'Online' },
			{ id: '3', name: 'Link de Pagamento' },
		],
		bandeiras: [
			{ id: 'american express', name: 'American Express' },
			{ id: 'elo', name: 'Elo' },
			{ id: 'maestro', name: 'Maestro' },
			{ id: 'mastercard', name: 'Mastercard' },
			{ id: 'visa', name: 'Visa' },
			{ id: 'visa electron', name: 'Visa Electron' },
			{ id: 'diners club', name: 'Diners Club' },
			{ id: 'hiper', name: 'Hiper' },
			{ id: 'hipercard', name: 'HiperCard' },
			{ id: 'banescard', name: 'Banescard' },
		],
	};

	const { formaPagamento, statusVenda, bandeiras, tipoVenda } = result;

	return {
		formaPagamento,
		statusVenda,
		bandeiras,
		tipoVenda,
	};
};

const getBandeira = bandeira => {
	switch (bandeira) {
		case 'american express':
			return 'American Express';
		case 'elo':
			return 'Elo';
		case 'maestro':
			return 'Maestro';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'visa electron':
			return 'Visa Electron';
		case 'diners club':
			return 'Diners Club';
		case 'hiper':
			return 'Hiper';
		case 'hipercard':
			return 'HiperCard';
		case 'banescard':
			return 'Banescard';
		default:
			return bandeira;
	}
};

const getPaymentIcon = (paymentType, brand, className = null) => {
	brand = getBandeira(brand);
	if (paymentType === 1) {
		return (
			<Tooltip title="Boleto Bancário">
				<div>
					<FaBarcode size={25} className={className} />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 2) {
		switch (brand) {
			case 'Maestro':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={maestrologo} />
						</div>
					</Tooltip>
				);
			case 'Mastercard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={mastercard} />
						</div>
					</Tooltip>
				);
			case 'Visa Electron':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={visaElectron} />
						</div>
					</Tooltip>
				);
			case 'Visa':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={visa} />
						</div>
					</Tooltip>
				);
			case 'Elo':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={elo} />
						</div>
					</Tooltip>
				);
			case 'Diners Club':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={diners} />
						</div>
					</Tooltip>
				);
			case 'HiperCard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={hipercard} />
						</div>
					</Tooltip>
				);
			case 'Hiper':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={hiper} />
						</div>
					</Tooltip>
				);
			case 'Banescard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={banescard} />
						</div>
					</Tooltip>
				);
			default:
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
						<div>
							<FaRegCreditCard className={className} />
						</div>
					</Tooltip>
				);
		}
	}

	if (paymentType === 3) {
		switch (brand) {
			case 'Mastercard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={mastercard} />
						</div>
					</Tooltip>
				);
			case 'Visa':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={visa} />
						</div>
					</Tooltip>
				);
			case 'American Express':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={american} />
						</div>
					</Tooltip>
				);
			case 'Elo':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={elo} />
						</div>
					</Tooltip>
				);
			case 'Diners Club':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={diners} />
						</div>
					</Tooltip>
				);
			case 'HiperCard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={hipercard} />
						</div>
					</Tooltip>
				);
			case 'Hiper':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={hiper} />
						</div>
					</Tooltip>
				);
			case 'Banescard':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={banescard} />
						</div>
					</Tooltip>
				);
			default:
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCreditCard className={className} />
						</div>
					</Tooltip>
				);
		}
	}

	if (paymentType === 4) {
		return (
			<Tooltip title="Comissão">
				<div>
					<FaDollarSign />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 6) {
		return (
			<Tooltip title="Pix">
				<div style={{ width: 25, height: 25, marginTop: -8 }}>
					<PixIcon />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 10) {
		return (
			<Tooltip title="Bolepix">
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<img alt="" height={15} src={Bolepix} />
				</div>
			</Tooltip>
		);
	}
};

const useStyles = makeStyles(theme => ({
	label: {
		alignItems: 'center',
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
		// textAlign: 'center',
		whiteSpace: 'nowrap',
	},
	labelMarkup: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
		textAlign: 'center',
		whiteSpace: 'nowrap',
	},
	labelQuantidadeVendas: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
		textAlign: 'center',
		whiteSpace: 'nowrap',
		display: 'flex',
		justifyContent: 'center',
	},
	valor: {
		fontSize: 16,
		fontWeight: 700,
	},
	qtdVendas: {
		fontSize: 28,
	},
	markup: {
		fontSize: 22,
	},
	vendas: {
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	exportHistory: {
		color: '#49bb57',
		fontSize: 16,
		marginLeft: 8,
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonHistory: {
		color: '#53BD5C',
		border: `solid 1px #53BD5C`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#53d48a',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		marginTop: 16,
		borderRadius: 10,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	tableRow: {
		// textAlign: 'center',
		// fontWeight: 700,
		// height: 30,
		// display: 'flex',
		// alignItems: 'center',
		// borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	rowTitle: {
		margin: 0,
		textAlign: 'center',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
			marginTop: 24,
		},
	},
	rowText: {
		display: 'inline-block',
		fontWeight: 400,
		marginTop: 8,
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		alignItems: 'center',
	},
	hideOnXs: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	dataHoraXs: {
		[theme.breakpoints.down('xs')]: {
			fontSize: 10,
		},
	},
	paymentIcon: {
		fontSize: 20,
	},
	fixedFilter: {
		margin: 4,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		padding: '5px 10px',
		borderRadius: 30,
	},
	fixedFilterTitle: { fontWeight: 'bold', marginRight: 5 },
	fixedFilterValue: { fontSize: 11, marginRight: 10 },
	fixedFilterRemove: {
		cursor: 'pointer',
	},
	valorAprovado: {
		color: '#4caf50',
	},
	valorPendente: {
		color: '#ffc107',
	},
	valorCancelado: {
		color: '#f44336',
	},
	divTotal: {
		borderLeft: '4px solid #eee',
	},
	RenovarCacheButton: {
		height: 37,
		padding: '0 6px',
		backgroundColor: theme.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			color: '#FFF',
			backgroundColor: `${theme.palette.primary.main}`,
		},
		[theme.breakpoints.up('md')]: {
			width: '80%',
		},
	},
}));

const VendaRow = memo(props => {
	const classes = useStyles();

	const [venda] = useState(props.venda || {});
	// const { isMaster } = props;

	if (!venda) {
		return null;
	}
	return (
		<Grid item xs={12} sm={12} style={{ margin: '8px 0' }}>
			<Paper className={classes.tableRow} onClick={() => props.rowClick(venda.id)}>
				<Grid container style={{ padding: 20 }}>
					<Grid item xs={6} sm={1}>
						<div
							style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
						>
							<div style={{ marginRight: 10, fontSize: 20 }}>{getStatusIcon(venda.status_pedido_id)}</div>
							<div style={{ marginRight: 5, fontSize: 20 }}>
								{getPaymentIcon(venda.tipo_pagamento_id, venda.bandeira, classes.paymentIcon)}
							</div>
							<div style={{ marginRight: 2, fontSize: 10, width: 20, textAlign: 'left' }}>
								{getPaymentIndicator(venda.tipo_pagamento_id)}
								{venda.tipo_pagamento_id === 3 ? ` ${venda.parcelas}x` : ' '}
							</div>
						</div>
					</Grid>
					{props.viewEC && (
						<Grid item xs={6} sm className={classes.truncate}>
							<p className={classes.rowTitle} style={{ margin: 0 }}>
								{venda.estabelecimento.nome_fantasia}
								<br />
								<span className={classes.rowText}>
									{' '}
									{/* {isMaster && !venda.markupCalculado && 'ㆍ'} */}
									{format(venda.created, 'DD/MM HH:mm')}
								</span>
							</p>
						</Grid>
					)}
					<Grid item xs={4} sm={1}>
						<p className={classes.rowTitle}>
							Valor <br />
							<span className={classes.rowText}>{Formatter.Real(venda.valor_bruto)} </span>
						</p>
					</Grid>
					{/* {agruparTaxas ? ( */}
					<Grid item xs={4} sm={1}>
						<p className={classes.rowTitle}>
							Taxas <br />{' '}
							<span className={classes.rowText}>
								{Formatter.Real(
									venda.status_pedido_id === 2 ? venda.valor_bruto - venda.valor_liquido : 0
								)}
							</span>
						</p>
					</Grid>
					{/* ) : (
						<>
							<Grid item xs={4} sm={1}>
								<p className={classes.rowTitle}>
									{' '}
									Taxa <br />{' '}
									<span className={classes.rowText}>{Formatter.Real(venda.valores.taxa)}</span>
								</p>
							</Grid>
							<Grid item xs={4} sm={1}>
								<p className={classes.rowTitle}>
									Split
									<br />
									<span className={classes.rowText}>{Formatter.Real(venda.valores.split)}</span>
								</p>
							</Grid>
						</>
					)} */}
					<Grid item xs={4} sm={1}>
						<p className={classes.rowTitle}>
							Liquido <br />
							<span className={classes.rowText}>{Formatter.Real(venda.valor_liquido)}</span>
						</p>
					</Grid>
					{/* <Grid item xs={2} className={classes.hideOnXs}> 
						 <p className={classes.rowTitle}>
							Vencimento
							<br />
							<span className={classes.rowText}>
								{Isset(() => venda.tipo_pagamento_id) &&
									// venda.tipo_pagamento_id === 1 &&
									venda.vencimento &&
									format(venda.vencimento.substring(0, 10), 'DD/MM/YYYY')}
							</span>
						</p> 
					{/* </Grid> */}
					<Grid item xs={6} sm={4} className={classes.truncate}>
						<p className={classes.rowTitle}>
							Cliente <br />
							<span className={classes.rowText}>
								{Isset(() => venda.cliente.nome) ? venda.cliente.nome : <em>Sem Cliente</em>}
							</span>
						</p>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
});

const VendasList = memo(props => {
	const classes = useStyles();
	const matches = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const { agruparTaxas } = props;

	const [vendas, setVendas] = useState([]);
	const [loading, setLoading] = useState(true);
	// const [totais, setTotais] = useState({
	// 	geral: {
	// 		quantidade: 0,
	// 		total: 0,
	// 		taxa: 0,
	// 		split: 0,
	// 		liquido: 0,
	// 	},
	// });

	useEffect(() => {
		Isset(() => props.vendas) && setVendas(props.vendas);
		Isset(() => props.loading) && setLoading(props.loading);
		// Isset(() => props.totais) && setTotais(props.totais);
	}, [props]);

	const usuario = useSelector(store => store.auth.usuario);
	const isMaster = useSelector(store => store.auth.isMaster);
	const viewEC = [1, 2, 4, 5, 7, 10, 11, 12, 13, 15].includes(usuario.grupo_id);

	if (!vendas) {
		return <ZEmpty height={200} text="Nenhuma Venda Encontrada." />;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{/* <Paper style={{ padding: 15 }}> */}
				{loading ? (
					<ZLoader height={200} />
				) : (
					<Grid container className={classes.vendas}>
						{/* <Grid container item className={classes.tableHeader}>
							<Grid item xs={1}>
								Hora
							</Grid>
							<Grid item xs={1}></Grid>
							{viewEC && (
								<Grid item xs>
									Estabelecimento
								</Grid>
							)}

							<Grid item sm={1} className={classes.hideOnXs}>
								Valor
							</Grid>
							<Grid item sm={1} className={classes.hideOnXs}>
								Taxa
							</Grid>
							{!agruparTaxas && (
								<Grid item sm={1} className={classes.hideOnXs}>
									Split
								</Grid>
							)}
							<Grid item xs={3} sm={1} style={{ fontSize: 16 }}>
								Líquido
							</Grid>
							<Grid item xs={2}>
								Vencimento
							</Grid>
							<Grid item xs={3} sm={2}>
								Cliente
							</Grid>
						</Grid> */}
						{vendas.map((venda, key) => {
							// if (
							// 	venda.tipo_pagamento_id === 1 &&
							// 	venda.status.id === 1 &&
							// 	moment(venda.dataRecebimento).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
							// 	(!props.filter.omni && !props.filter.omni2 && !props.filter.status.length && !props.filter.statusVenda.length)

							// ) {
							// 	return '';
							// }

							return (
								<React.Fragment key={key}>
									{venda.type === 'date' ? (
										<Grid container item className={classes.tableDateRow}>
											<Grid item xs={6} sm={agruparTaxas ? 5 : 4} style={{ paddingLeft: 20 }}>
												{format(
													venda.created,
													`${matches ? 'DD/MM/YYYY' : 'DD [de] MMMM [de] YYYY'}`,
													{
														locale: pt,
													}
												)}
											</Grid>
											{usuario.grupo_id !== 7 && (
												<>
													{/* <Grid
														item
														xs={1}
														className={classes.hideOnXs}
														style={{ textAlign: 'center' }}
													>
														{Isset(() => totais[venda.date].bruto)
															? Formatter.Real(totais[venda.date].bruto)
															: ''}
													</Grid>
													<Grid
														item
														xs={agruparTaxas ? 1 : 2}
														className={classes.hideOnXs}
													></Grid>
													<Grid item xs={2} sm={1} style={{ textAlign: 'center' }}>
														{Isset(() => totais[venda.date].liquido)
															? Formatter.Real(totais[venda.date].liquido)
															: ''}
													</Grid> */}
												</>
											)}
										</Grid>
									) : (
										<VendaRow
											venda={venda}
											rowClick={props.rowClick}
											viewEC={viewEC}
											agruparTaxas={agruparTaxas}
											isMaster={isMaster}
										/>
									)}
								</React.Fragment>
							);
						})}
					</Grid>
				)}
				{/* </Paper> */}
			</Grid>
		</Grid>
	);
});

const FixedFilter = props => {
	const classes = useStyles();
	return (
		<div className={classes.fixedFilter}>
			<span className={classes.fixedFilterTitle}>{props.title}</span>
			<span className={classes.fixedFilterValue}>{props.value}</span>
			<span className={classes.fixedFilterRemove} onClick={props.onClick}>
				<FaTimes />
			</span>
		</div>
	);
};

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = queryString.parse(useLocation().search);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	// const axiosCancelSource = axios.CancelToken.source();
	const [showModalRenovaCache, setShowModalRenovarCache] = useState(false);

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});
	if (filtersCache.hasOwnProperty('data_recebimento')) {
		delete filtersCache.data_recebimento;
	}

	const history = useHistory();
	const usuario = useSelector(store => store.auth.usuario);
	const token = useSelector(store => store.auth.token);
	// const localFilters = useSelector(store => store.system.filters[location.pathname] || {});
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const visualizarSplit = useSelector(store =>
		Isset(() => store.system.params['34'].valor) ? store.system.params['34'].valor : null
	);
	const agruparTaxas = useSelector(store =>
		Isset(() => store.system.params['1'].valor) ? store.system.params['1'].valor : null
	);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const dataInicial = new Date().setHours(0, 0, 0, 0);

	let queryFilter = {
		// data_venda: { de: new Date().setHours(0, 0, 0, 0), ate: new Date() },
		data_venda: { de: new Date(dataInicial), ate: new Date() },
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		reference: '',
		status: [],
		valor: 0,
		valor2: 0,
		omni: '',
		omni2: '',
		zoopTransactionId: null,
		...filtersCache,
	};

	const [data, setData] = useState({
		vendas: [],
	});
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [showLastExport, setShowLastExport] = useState(false);

	const [loaderResumo, setLoaderResumo] = useState(false);
	const [loaderResumoAprovado, setLoaderResumoAprovado] = useState(false);
	const [totalAprovado, setTotalAprovado] = useState({
		quantidade: 0,
	});
	const [totais, setTotais] = useState({
		geral: {
			quantidade: 0,
			totalAprovado: 0,
			totalFalhado: 0,
			totalCancelado: 0,
			totalEstornado: 0,
			totalPendente: 0,
			taxa: 0,
			split: 0,
			liquido: 0,
			markup: 0,
		},
	});

	// const [totaisValores, setTotaisValores] = useState({});

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(200);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getTotais = async () => {
		setLoaderResumo(true);
		setTotais({
			geral: {
				quantidade: 0,
				totalAprovado: 0,
				totalFalhado: 0,
				totalCancelado: 0,
				totalEstornado: 0,
				totalPendente: 0,
				taxa: 0,
				split: 0,
				liquido: 0,
				markup: 0,
			},
		});

		const paramsAux = { ...filtros };
		delete paramsAux.data_venda;
		delete paramsAux.estabelecimentoId;

		const params = {
			startDate: new Date(filtros.data_venda.de),
			endDate: new Date(filtros.data_venda.ate),
			estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
			...paramsAux,
		};

		const resTotais = await axios.get(`${config.get('apiUrl')}vendas/resumo`, {
			// cancelToken: axiosCancelSource.token,
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				...params,
				// filters: {
				// 	estabelecimentoId,
				// 	...filtros,
				// },
			},
		});

		if (resTotais.data.success) {
			setTotais(resTotais.data.totais);
			setTotalRows(resTotais.data.quantidade);
		}

		setLoaderResumo(false);
	};

	const getTotaisAprovados = async () => {
		setLoaderResumoAprovado(true);
		const paramsAux = { ...filtros };
		delete paramsAux.data_venda;
		delete paramsAux.estabelecimentoId;

		const params = {
			startDate: new Date(filtros.data_venda.de),
			endDate: new Date(filtros.data_venda.ate),
			estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
			...paramsAux,
		};
		const resTotaisAprovadas = await axios.get(`${config.get('apiUrl')}vendas/resumo/quantidade-vendas-aprovadas`, {
			// cancelToken: axiosCancelSource.token,
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				...params,
				// filters: {
				// 	estabelecimentoId,
				// 	...filtros,
				// },
			},
		});

		if (resTotaisAprovadas.data.success) {
			setTotalAprovado(resTotaisAprovadas.data.totais.geral.quantidade);
		}
		setLoaderResumoAprovado(false);
	};

	const getData = async () => {
		setData({ vendas: [] });
		// setTotais({
		// 	geral: {
		// 		quantidade: 0,
		// 		totalAprovado: 0,
		// 		totalFalhado: 0,
		// 		totalCancelado: 0,
		// 		totalEstornado: 0,
		// 		totalPendente: 0,
		// 		taxa: 0,
		// 		split: 0,
		// 		liquido: 0,
		// 		markup: 0,
		// 	},
		// });
		setLoading(true);

		const paramsAux = { ...filtros };
		delete paramsAux.data_venda;
		delete paramsAux.estabelecimentoId;

		const params = {
			startDate: new Date(filtros.data_venda.de),
			endDate: new Date(filtros.data_venda.ate),
			estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
			...paramsAux,
		};
		const resVendas = await axios.get(`${config.get('apiUrl')}vendas/lista`, {
			// cancelToken: axiosCancelSource.token,
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				...params,
				// filters: {
				// 	estabelecimentoId,
				// 	...filtros,
				// },
			},
		});

		if (resVendas.data.success) {
			// const totaisValores = resVendas.data.totaisValores
			// resTotais.data.totais = {
			// 	... resTotais.data.totais,
			// 	...totaisValores
			// }
			// setTotais(resTotais.data.totais);
			// setTotaisValores(resVendas.data.totaisValores);

			setData({
				vendas: resVendas.data.vendas.map(v => {
					let tipoPagamentoId;

					switch (v.tipo_pagamento) {
						case 'credit':
							tipoPagamentoId = 3;
							break;
						case 'debit':
							tipoPagamentoId = 2;
							break;
						case 'pix':
							tipoPagamentoId = 6;
							break;
						case 'boleto':
							tipoPagamentoId = 1;
							break;
						case 'bolepix':
							tipoPagamentoId = 10;
							break;
						default:
							tipoPagamentoId = undefined;
							break;
					}

					return {
						id: v.id,
						bandeira: v.bandeira,
						estabelecimento: {
							id: v.estabelecimento.id,
							nome_fantasia: v.estabelecimento.nome_fantasia,
						},
						created: v.created,
						valor_bruto: v.valor_bruto,
						valor_liquido: v.status_pedido_id === 2 ? v.valor_liquido : 0,
						taxa: undefined,
						parcelas: v.parcelas,
						status: v.status_pedido_id,
						status_pedido_id: v.status_pedido_id,
						tipo_pagamento_id: tipoPagamentoId,
						vencimento: undefined,
						cliente: {
							id: v.cliente?.id ? v.cliente?.id : undefined,
							nome: v.cliente?.nome ? v.cliente?.nome : `Não Identificado`,
						},
					};
				}),
			});
			// setTotalRows(resTotais.data.quantidade);
		}
		setLoading(false);
	};

	const [dataRecebimento, setDataRecebimento] = useState({
		start: null,
		end: null,
		label: 'Data de Recebimento',
	});

	const [filtros, setFiltros] = useState({
		...queryFilter,
		pos: query.pos || '',
		omni2: Isset(() => query.estabelecimentoNome) ? query.estabelecimentoNome : queryFilter.omni2,
		data_venda: {
			de: Isset(() => query.startDate) ? query.startDate : queryFilter.data_venda.de,
			ate: Isset(() => query.endDate) ? query.endDate : queryFilter.data_venda.ate,
		},
	});

	useEffect(() => {
		getTotais();
		getTotaisAprovados();
		// return async () => {
		// 	axiosCancelSource.cancel('Vendas Unmounted.');
		// };
	}, [filtros]);

	useEffect(() => {
		getData();
		// dispatch(setFilters(location.pathname, filtros));
		// eslint-disable-next-line react-hooks/exhaustive-deps
		// return async () => {
		// 	axiosCancelSource.cancel('Vendas Unmounted.');
		// };
	}, [filtros, page, rowsPerPage]);

	// useEffect(() => {
	// 	if (totaisValores) {
	// 		const totaisAux = {
	// 			...geral,
	// 			...totaisValores
	// 		}
	// 		setTotais(totaisAux)
	// 	}
	// }, [totaisValores, geral]);

	const filtrosChange = async data => {
		setPage(1);
		data.data_venda = {
			de: data.date.start,
			ate: data.date.end,
		};

		if (data.created) {
			if (data.created.start || data.created.end) {
				data.data_recebimento = {
					de: data.created.start,
					ate: data.created.end,
				};

				setDataRecebimento({ ...dataRecebimento, start: data.created.start, end: data.created.end });
			} else {
				delete data.data_recebimento;
				delete data.created;
				setDataRecebimento({ ...dataRecebimento, start: null, end: null });
			}
		}

		const valor = data.valor ? parseInt(data.valor.replace(/[^\d]+/g, '')) / 100 : null;
		const valor2 = data.valor2 ? parseInt(data.valor2.replace(/[^\d]+/g, '')) / 100 : null;

		if (valor && valor2) {
			data.price_range = [valor, valor2];
		} else if (!valor && valor2) {
			data.price_range = [0, valor2];
		}

		data.estabelecimentoId = [estabelecimentoId];

		data.status = data.statusVenda ? data.statusVenda : [];

		if (Array.isArray(data.estabelecimento)) {
			data.estabelecimentoId = [estabelecimentoId, ...data.estabelecimento];
		}

		data.pos = query.pos || '';

		if (Isset(() => query.estabelecimento) && query.estabelecimento !== '') {
			data.estabelecimentoId = [query.estabelecimento];
		}

		setFiltros({ ...data, loading: loading });
	};

	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId: Isset(() => query.estabelecimento) ? [query.estabelecimento] : [estabelecimentoId],
		});
	}, [estabelecimentoId]);

	const handleClearFilter = () => {
		queryFilter = {
			data_venda: { de: moment(new Date()).format('YYYY-MM-DD'), ate: moment(new Date()).format('YYYY-MM-DD') },
			estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
			status: [],
			valor: 0,
			valor2: 0,
			omni: '',
			omni2: '',
			zoopTransactionId: null,
		};

		setDataRecebimento({ ...dataRecebimento, start: null, end: null });
		delete filtersCache.data_recebimento;
		setFiltros({
			...queryFilter,
			pos: query.pos || '',
			omni2: Isset(() => query.estabelecimentoNome) ? query.estabelecimentoNome : queryFilter.omni2,
			data_venda: {
				de: Isset(() => query.startDate) ? query.startDate : queryFilter.data_venda.de,
				ate: Isset(() => query.endDate) ? query.endDate : queryFilter.data_venda.ate,
			},
		});
	};

	const exportXls = async getLastExports => {
		try {
			const paramsAux = { ...filtros };
			delete paramsAux.data_venda;
			delete paramsAux.estabelecimentoId;

			const params = {
				startDate: new Date(filtros.data_venda.de),
				endDate: new Date(filtros.data_venda.ate),
				estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
				...paramsAux,
			};
			const startDate = filtros.data_venda.de;
			const endDate = filtros.data_venda.ate;
			const daysDifference = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));

			if (daysDifference > 31) {
				dispatch(addMessage({ type: 'error', message: 'O intervalo de data é de no máximo 31 dias.' }));
				setShowLastExport(false);
			} else {
				await axios.get(`${config.get('apiUrl')}export/vendas`, {
					headers: { Authorization: `Bearer ${token}` },
					responseType: 'blob',
					params: {
						rows: totalRows,
						...params,
						// filters: {
						// 	estabelecimentoId,
						// 	...filtros,
						// },
						operarMarketplace,
					},
				});
				getLastExports();

				dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));
			}
		} catch (error) {
			console.log(error.response.data.text());
			const texto = await error.response.data.text();
			console.log(texto);
			dispatch(addMessage({ type: 'error', message: texto }));
		}
		setShowExportMessage(false);
	};

	const exportXlsClick = async () => {
		// if (totalRows > 20000) {
		setShowLastExport(true);
		// } else {
		// 	await exportXls();
		// }
	};
	const [exportInProgress, setExportInProgress] = useState(false);

	const newExport = async getLastExports => {
		setExportInProgress(true);
		await exportXls(getLastExports);
		setExportInProgress(false);
	};

	const rowClick = vendaId => {
		history.push(`/vendas/detalhes/${vendaId}`);
	};

	const hideExport = [7];

	const handleRenovarCache = result => {
		if (result) {
			setFiltros({
				...filtros,
				noCache: true,
			});
		}
		setShowModalRenovarCache(false);
	};

	return (
		<Grid className={classes.vendas}>
			<div className={classes.header}>Vendas</div>

			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
				<Grid container alignItems="center">
					<Grid item xs={12} md={11}>
						<ZFiltros
							onChange={filtrosChange}
							handleClearFilter={() => handleClearFilter()}
							modal={true}
							dateRange={{
								default: 'custom',
								startDate: filtros.data_venda.de,
								endDate: filtros.data_venda.ate,
								label: 'Clique para selecionar a data',
							}}
							created={{
								...dataRecebimento,
								modal: true,
							}}
							input={[
								{
									key: 'omni2',
									placeholder: 'Estabelecimento',
									defaultValue: filtros.omni2,
									modal: true,
									md: 6,
								},
								{
									key: 'reference',
									placeholder: 'Referência',
									defaultValue: filtros.reference,
									modal: true,
									md: 6,
								},
								// {
								// 	key: 'omni',
								// 	placeholder: 'CPF/CNPJ',
								// 	defaultValue: filtros.omni,
								// 	mask: 'documento',
								// 	modal: true,
								// 	md: 6,
								// },
								{
									key: 'cliente',
									placeholder: 'Cliente',
									defaultValue: filtros.cliente || '',
									modal: true,
									md: 6,
								},
								{
									key: 'zoopTransactionId',
									placeholder: 'Zoop Transaction ID',
									defaultValue: filtros.zoopTransactionId || null,
									modal: true,
									md: 6,
								},
							]}
							//colocar multiple: true  caso queira disponibilizar mais seleçoes de filtro
							selectField={[
								{
									key: 'statusVenda',
									defaultValue: filtros.statusVenda,
									title: 'Status da Venda',
									multiple: true,
									options: getOptions().statusVenda,
									modal: true,
									md: 12,
									divider: true,
								},
								{
									key: 'tipoVenda',
									defaultValue: filtros.tipoVenda,
									title: 'Tipo de Venda',
									multiple: false,
									options: getOptions().tipoVenda,
									modal: true,
									md: 12,
									divider: true,
								},
								{
									key: 'bandeira',
									defaultValue: filtros.bandeira,
									multiple: true,
									title: 'Bandeira',
									options: getOptions().bandeiras,
									modal: true,
									md: 12,
									divider: true,
								},
								{
									key: 'formaPagamento',
									defaultValue: filtros.formaPagamento,
									multiple: true,
									title: 'Forma de Pagamento',
									options: getOptions().formaPagamento,
									modal: true,
									md: 12,
									divider: true,
								},
							]}
							priceRange={[
								{
									key: 'valor',
									title: 'De',
									defaultValue: filtros.valor,
									mask: 'real',
								},
								{
									key: 'valor2',
									title: 'Até',
									defaultValue: filtros.valor2,
									mask: 'real',
								},
							]}
							checkbox={[
								{
									key: 'transacoesSuspeitas',
									placeholder: 'Transações Suspeitas',
									defaultValue: false,
									modal: true,
									md: 6,
								},
							]}
						/>
					</Grid>
					<Grid container spacing={1} item md={1}>
						{format(new Date(filtros.data_venda.de), 'YYYY-MM-DD') < format(new Date(), 'YYYY-MM-DD') && (
							<Grid item align="center" xs={4} md={4}>
								{usuario.grupo_id === 1 && (
									<Tooltip title="Renovar Cache">
										<Button
											className={clsx(classes.buttonExport, classes.iconButton)}
											onClick={() => {
												setShowModalRenovarCache(true);
											}}
										>
											<FiRefreshCw className={classes.exportDownload} />
										</Button>
									</Tooltip>
								)}
							</Grid>
						)}
						<Grid item align="center" xs={4} md={4}>
							{!hideExport.includes(usuario.grupo_id) && (
								<Tooltip title="Exportar">
									<Button
										className={clsx(classes.buttonExport, classes.iconButton)}
										onClick={exportXlsClick}
									>
										<FaDownload className={classes.exportDownload} />
									</Button>
								</Tooltip>
							)}
						</Grid>
						<Grid item align="center" xs={4} md={4}>
							{!hideExport.includes(usuario.grupo_id) && (
								<Tooltip title="Histórico">
									<Button
										className={clsx(classes.buttonHistory, classes.iconButton)}
										onClick={() => setShowLastExport(true)}
									>
										<FaHistory className={classes.exportHistory} />
									</Button>
								</Tooltip>
							)}
						</Grid>
					</Grid>
				</Grid>
				<div style={{ display: 'flex' }}>
					{query.pos && (
						<FixedFilter
							title="POS"
							value={query.pos}
							onClick={async () => {
								delete query.pos;
								await setFiltros({ ...filtros, pos: '' });
								history.push(`/vendas?${queryString.stringify(query)}`);
							}}
						/>
					)}
					{query.estabelecimento && (
						<FixedFilter
							title="Estabelecimento"
							value={query.estabelecimentoNome}
							onClick={async () => {
								delete query.estabelecimento;
								await setFiltros({
									...filtros,
									estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
								});
								history.push(`/vendas?${queryString.stringify(query)}`);
							}}
						/>
					)}
				</div>
			</Paper>

			{usuario.grupo_id !== 7 && (
				<Paper style={{ padding: 15, marginBottom: 10 }}>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={6} sm={3} md={3}>
							<div className={classes.labelQuantidadeVendas}>
								<div style={{ padding: '0 8px' }}>
									Total
									<div className={clsx(classes.valor, classes.qtdVendas)} style={{ width: '130px' }}>
										{loaderResumo ? (
											<span
												className={clsx(classes.valor, classes.valorAprovado)}
												style={{ marginBottom: '2px' }}
											>
												<Skeleton
													style={{ margin: 'auto' }}
													animation="wave"
													variant="text"
													width={130}
													height={42}
												/>
											</span>
										) : (
											<span style={{ width: '130px', height: '42px' }}>
												{Formatter.Valor(
													Isset(() => totais.geral.quantidade) ? totais.geral.quantidade : 0,
													0,
													',',
													'.'
												)}
											</span>
										)}
									</div>
								</div>
								{Isset(() => filtros.status) && filtros.status.length === 0 && !filtros.reference && (
									<div
										style={{ padding: '0 8px' }}
										className={clsx(classes.hideOnXs, classes.divTotal)}
									>
										Aprovadas
										<div
											className={clsx(classes.valor, classes.qtdVendas)}
											style={{ width: '130px' }}
										>
											{loaderResumoAprovado ? (
												<span
													className={clsx(classes.valor, classes.valorAprovado)}
													style={{ marginBottom: '2px' }}
												>
													<Skeleton
														style={{ margin: 'auto' }}
														animation="wave"
														variant="text"
														width={130}
														height={42}
													/>
												</span>
											) : (
												<span style={{ width: '130px', height: '42px' }}>
													{Formatter.Valor(
														Isset(() => totalAprovado) ? totalAprovado : 0,
														0,
														',',
														'.'
													)}
												</span>
											)}
										</div>
									</div>
								)}
							</div>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs}>
							<Grid container className={classes.label} spacing={1} justifyContent="center">
								{loaderResumo ? (
									<Grid item>
										<Skeleton animation="wave" variant="text" width={170} height={22} />
									</Grid>
								) : (
									<>
										<Grid item style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}>
											<span>{`Aprovado `}</span>
										</Grid>
										<Grid item style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}>
											<span className={clsx(classes.valor, classes.valorAprovado)}>
												{Formatter.Real(
													Isset(() => totais.geral.totalAprovado)
														? totais.geral.totalAprovado
														: 0
												)}
											</span>
										</Grid>
									</>
								)}
							</Grid>
							{agruparTaxas || !visualizarSplit ? (
								<Grid container className={classes.label} spacing={1} justifyContent="center">
									{loaderResumo ? (
										<Grid item>
											<Skeleton animation="wave" variant="text" width={170} height={22} />
										</Grid>
									) : (
										<>
											<Grid item style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}>
												<span>{`Taxa `}</span>
											</Grid>
											<Grid item style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}>
												<span className={classes.valor}>
													{Formatter.Real(
														+totais.geral.taxa +
															+totais.geral.split2 +
															+totais.geral.planoTaxa
													)}
												</span>
											</Grid>
										</>
									)}
								</Grid>
							) : (
								<>
									<Grid container className={classes.label} spacing={1} justifyContent="center">
										{loaderResumo ? (
											<Grid item>
												<Skeleton animation="wave" variant="text" width={170} height={22} />
											</Grid>
										) : (
											<>
												<Grid
													item
													style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}
												>
													<span>{`Taxa `}</span>
												</Grid>
												<Grid
													item
													style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}
												>
													<span className={classes.valor}>
														{Formatter.Real(+totais.geral.taxa + +totais.geral.planoTaxa)}
													</span>
												</Grid>
											</>
										)}
									</Grid>
								</>
							)}
							{visualizarSplit && (
								<>
									<Grid className={classes.label} container spacing={1} justifyContent="center">
										{loaderResumo ? (
											<Grid item>
												<Skeleton animation="wave" variant="text" width={170} height={22} />
											</Grid>
										) : (
											<>
												<Grid
													item
													style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}
												>
													<span>{`Split `}</span>
												</Grid>
												<Grid
													item
													style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}
												>
													<span className={classes.valor}>
														{Formatter.Real(
															Isset(() => totais.geral.split2) ? totais.geral.split2 : 0
														)}
													</span>
												</Grid>
											</>
										)}
									</Grid>
								</>
							)}
							<Grid container className={classes.label} spacing={1} justifyContent="center">
								{loaderResumo ? (
									<Grid item>
										<Skeleton animation="wave" variant="text" width={170} height={22} />
									</Grid>
								) : (
									<>
										<Grid item style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}>
											<span>{`Líquido `}</span>
										</Grid>
										<Grid item style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}>
											<span className={classes.valor}>
												{Formatter.Real(
													Isset(() => totais.geral.liquido) ? totais.geral.liquido : 0
												)}
											</span>
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							<Grid container className={classes.label} spacing={1} justifyContent="center">
								{loaderResumo ? (
									<Grid item>
										<Skeleton animation="wave" variant="text" width={170} height={22} />
									</Grid>
								) : (
									<>
										<Grid item style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}>
											<span>{`Pendente `}</span>
										</Grid>
										<Grid item style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}>
											<span className={clsx(classes.valor)}>
												{Formatter.Real(
													Isset(() => totais.geral.totalPendente)
														? totais.geral.totalPendente
														: 0
												)}
											</span>
										</Grid>
									</>
								)}
							</Grid>
							<Grid container className={classes.label} spacing={1} justifyContent="center">
								{loaderResumo ? (
									<Grid item>
										<Skeleton animation="wave" variant="text" width={170} height={22} />
									</Grid>
								) : (
									<>
										<Grid item style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}>
											<span>{`Falhado `}</span>
										</Grid>
										<Grid item style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}>
											<span className={clsx(classes.valor)}>
												{Formatter.Real(
													Isset(() => totais.geral.totalFalhado)
														? totais.geral.totalFalhado
														: 0
												)}
											</span>
										</Grid>
									</>
								)}
							</Grid>
							<Grid container className={classes.label} spacing={1} justifyContent="center">
								{loaderResumo ? (
									<Grid item>
										<Skeleton animation="wave" variant="text" width={170} height={22} />
									</Grid>
								) : (
									<>
										<Grid item style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}>
											<span>{`Cancelado `}</span>
										</Grid>
										<Grid item style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}>
											<span className={clsx(classes.valor)}>
												{Formatter.Real(
													Isset(() => totais.geral.totalCancelado)
														? totais.geral.totalCancelado
														: 0
												)}
											</span>
										</Grid>
									</>
								)}
							</Grid>
							<Grid container className={classes.label} spacing={1} justifyContent="center">
								{loaderResumo ? (
									<Grid item>
										<Skeleton animation="wave" variant="text" width={170} height={22} />
									</Grid>
								) : (
									<>
										<Grid item style={{ maxWidth: '80px', width: '50%', textAlign: 'start' }}>
											<span>{`Estornado `}</span>
										</Grid>
										<Grid item style={{ maxWidth: '150px', width: '50%', textAlign: 'end' }}>
											<span className={clsx(classes.valor)}>
												{Formatter.Real(
													Isset(() => totais.geral.totalEstornado)
														? totais.geral.totalEstornado
														: 0
												)}
											</span>
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
						{operarMarketplace && (
							<Grid item xs={6} sm={3} md={3}>
								<div className={classes.labelMarkup}>
									Markup
									<div className={clsx(classes.valor, classes.markup)}>
										{loaderResumo ? (
											<Skeleton
												style={{ margin: 'auto' }}
												animation="wave"
												variant="text"
												width={80}
												height={42}
											/>
										) : (
											Formatter.Real(
												(Isset(() => totais.geral.markup) ? +totais.geral.markup : 0) +
													+totais.geral.markupEC
											)
										)}
									</div>
								</div>
							</Grid>
						)}
					</Grid>
				</Paper>
			)}

			<VendasList
				vendas={data.vendas}
				loading={loading}
				// totais={totaisValores}
				rowClick={rowClick}
				agruparTaxas={agruparTaxas}
				filter={queryFilter}
			/>

			<ZPaginationDiv
				count={totalRows}
				rowsPerPage={rowsPerPage}
				page={page - 1}
				onChangePage={onChangePage}
				onChangeRowsPerPage={onChangeRowsPerPage}
				rowsPerPageOptions={[50, 100, 200]}
			/>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>

			{showLastExport && (
				<LastExport
					show={showLastExport}
					onClose={() => setShowLastExport(false)}
					url={'/export/vendas'}
					newExport={newExport}
					loadingProp={loading}
					exportInProgress={exportInProgress}
					setExportInProgress={setExportInProgress}
				/>
			)}

			<ZModalConfirm
				title={`Regeneração do resumo`}
				message={`Ao clicar em continuar, iremos recriar os dados do resumo das vendas`}
				open={showModalRenovaCache}
				onClose={result => handleRenovarCache(result)}
			/>
		</Grid>
	);
};
