import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ZTitle from '../system/ZTitle';
import ZText from '../system/ZText';
import ZSelect from '../system/ZSelect';
import ZSaveButton from '../system/ZSaveButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import { addMessage } from '../../store/alert';
import ZErrors from '../system/ZErrors';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import ZFiltros from '../system/ZFiltros';
import ZLoader from '../system/ZLoader';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		marginTop: 15,
		width: '100%',
	},
	ecs: {
		width: '100%',
		height: 16,
		'& .MuiTypography-body1': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
}));

const validateFields = {
	estabelecimentoId: {
		required: true,
	},
	usuario: {
		nome: {
			required: true,
		},
		email: {
			required: true,
			email: true,
		},
		cpf: {
			required: true,
		},
		dataNascimento: {
			required: true,
		},
		sexo: {
			required: true,
		},
	},
	contatos: [
		{
			valorContato: {
				required: true,
			},
		},
	],
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cep: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
	},
};

export default props => {
	// const { history, match } = useReactRouter();
	const history = useHistory();
	const match = useRouteMatch();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);
	const classes = useStyles();
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const isAdmin = usuario.grupo_id === 1;

	const [pageTitle, setPageTitle] = useState('Adicionar novo usuário');
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);
	const [canSplitChecked, setCanSplitChecked] = useState(false);

	const [data, setData] = useState({
		estabelecimentoId: usuario.estabelecimento.id,
		usuario: {
			nome: '',
			email: '',
			// cpf: '',
			dataNascimento: '',
			dataNascimento2: '',
			sexo: '',
			grupoId: '',
			parentId: null,
		},
		estabelecimentosSelecionados: [],
		documentos: [
			{
				id: null,
				tipoDocumento: 2,
				documento: '',
			},
		],
		contatos: [
			{
				id: null,
				tipoContato: 2,
				valorContato: '',
			},
			{
				id: null,
				tipoContato: 1,
				valorContato: '',
			},
		],
		endereco: {
			logradouro: '',
			numero: '',
			cep: '',
			cidade: '',
			estado: '',
			complemento: '',
		},
	});

	const [errors, setErrors] = useState([]);
	const [openEstabelecimentos, setOpenEstabelecimentos] = useState(false);
	const [ecs, setECs] = useState([]);
	const [ecsLoading, setECsLoading] = useState(true);
	const [ecsFilters, setECsFilters] = useState({
		omniEstabelecimento: null,
	});
	const [representantes, setRepresentantes] = useState([]);
	const [representante, setRepresentante] = useState(false);

	const getData = async usuarioId => {
		setPageTitle('Editar usuário');
		var estabelecimentoId = estabelecimentoAdmin ? estabelecimentoAdmin.id : '';
		var dataUsuario = await axios.get(config.get('apiUrl') + 'usuarios/' + usuarioId + '/' + estabelecimentoId, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (dataUsuario.data.success) {
			var contato = [
				{
					id: null,
					tipoContato: 2,
					documento: '',
				},
				{
					id: null,
					tipoContato: 1,
					valorContato: '',
				},
			];

			if (dataUsuario.data.usuario.usuarios_contatos.length) {
				contato = dataUsuario.data.usuario.usuarios_contatos.map((value, key) => {
					return {
						id: value.id,
						tipoContato: value.tipo_contato_id,
						valorContato: value.contato,
					};
				});

				if (contato.length < 2) {
					contato.push({
						id: null,
						tipoContato: 1,
						valorContato: '',
					});
				}
			}

			var documentos = [
				{
					id: null,
					tipoDocumento: 2,
					documento: '',
				},
			];

			if (dataUsuario.data.usuario.usuarios_documentos.length) {
				documentos = dataUsuario.data.usuario.usuarios_documentos.map((value, key) => {
					return {
						id: value.id,
						tipoDocumento: value.tipo_documento_id,
						documento: value.documento,
					};
				});
			}

			setCanSplitChecked(dataUsuario.data.usuario.can_split);

			if ([5, 8].includes(dataUsuario.data.usuario.grupo.id)) {
				setRepresentante(true);
			} else {
				setRepresentante(false);
			}

			setData({
				...data,
				usuarioId: usuarioId,
				usuario: {
					...data.usuario,
					nome: dataUsuario.data.usuario.nome,
					email: dataUsuario.data.usuario.email,
					// celular: dataUsuario.data.usuario.nome,
					// cpf: dataUsuario.data.usuario.nome,
					dataNascimento2: moment(dataUsuario.data.usuario.data_nascimento, 'YYYY-MM-DD').format(
						'DD/MM/YYYY'
					),
					dataNascimento: dataUsuario.data.usuario.data_nascimento,
					sexo: dataUsuario.data.usuario.sexo,
					grupoId: dataUsuario.data.usuario.grupo.id,
					parentId: dataUsuario.data.usuario.parent_id,
				},
				documentos: documentos,
				contatos: contato,
				endereco: {
					logradouro: dataUsuario.data.usuario.endereco.logradouro,
					numero: dataUsuario.data.usuario.endereco.numero,
					cep: dataUsuario.data.usuario.endereco.cep,
					cidade: dataUsuario.data.usuario.endereco.cidade,
					estado: dataUsuario.data.usuario.endereco.uf,
					complemento: dataUsuario.data.usuario.endereco.complemento || '',
					bairro: dataUsuario.data.usuario.endereco.bairro || '',
				},
				estabelecimentosSelecionados: dataUsuario.data.usuario.estabelecimentos,
			});
		} else {
			dispatch(
				addMessage({
					type: 'error',
					message:
						'Esse usuário está com problemas de acesso. Entre em contato com o administrador do sistema',
				})
			);
		}
	};

	const getECs = async () => {
		setECsLoading(true);
		const res = await axios.post(
			`${config.get('apiUrl')}graphql`,
			{
				query: `
					query Estabelecimentos($filters: Filter) {
						estabelecimentos(filters: $filters) {
							id, nomeFantasia, razaoSocial
						}
					}
				`,
				variables: {
					filters: {
						...ecsFilters,
						usuarioId: data.usuario.parentId,
					},
				},
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.status === 200 && Isset(() => res.data.data.estabelecimentos)) {
			setECs(res.data.data.estabelecimentos);
		}
		setECsLoading(false);
	};

	useEffect(() => {
		let usuarioId = match.params.usuarioId;
		if (usuarioId > 1) {
			getData(usuarioId);
		}

		getECs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getECs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ecsFilters, data.usuario.parentId]);

	useEffect(() => {
		setRepresentantes([]);
		if ([5, 8].includes(data.usuario.grupoId)) {
			const getRepresentantes = async () => {
				const res = await axios.get(`${config.get('apiUrl')}usuarios`, {
					headers: { Authorization: `Bearer ${token}` },
					params: {
						limit: 9999,
						filters: {
							grupoId: 5,
						},
						sort: {
							field: 'nome',
							order: 'ASC',
						},
					},
				});

				if (res.data.success) {
					const usuarioId = match.params.usuarioId;
					let reps = res.data.usuarios.map(u => ({
						id: u.id,
						name: u.nome,
					}));
					if (usuarioId) {
						reps = reps.filter(a => +a.id !== +usuarioId);
					}
					setRepresentantes(reps);
				}
			};

			getRepresentantes();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.usuario.grupoId]);

	const handleChange = name => event => {
		if (name === 'dataNascimento2') {
			var newDate = moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

			if (newDate === 'Invalid date') {
				dispatch(addMessage({ type: 'error', message: 'Data inválida' }));
				return false;
			}

			setData({
				...data,
				usuario: {
					...data.usuario,
					[name]: event.target.value,
					dataNascimento: moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
				},
			});
		} else {
			var value = event.target.value;
			var onlyNumbers = ['cpf'];
			if (onlyNumbers.indexOf(name) !== -1) value = Mask.OnlyNumber(value);

			setData({
				...data,
				usuario: {
					...data.usuario,
					[name]: value,
				},
			});
		}
	};

	const handleChangeDocumento = (type, id) => async event => {
		var documentos = data.documentos;

		documentos.map((dataMap, key) => {
			var value = Mask.OnlyNumber(event.target.value);
			if (id === dataMap.id && id !== null) {
				documentos[key].documento = value;
			} else if (id == null && dataMap.tipoDocumento === type) {
				documentos[key].documento = value;
			}
			return true;
		});

		setData({
			...data,
			documentos: documentos,
		});
	};

	const grupoIdChange = e => {
		const value = e.target.value;
		if ([5, 8].includes(value)) {
			setRepresentante(true);
		} else {
			setRepresentante(false);
		}
		setData({
			...data,
			usuario: {
				...data.usuario,
				grupoId: value,
			},
		});
	};

	const handleChangeContato = (type, id) => async event => {
		var contatos = data.contatos;

		contatos.map((dataMap, key) => {
			var value = Mask.OnlyNumber(event.target.value);

			if (id === dataMap.id && id !== null) {
				contatos[key].valorContato = value;
			} else if (id == null && dataMap.tipoContato === type) {
				contatos[key].valorContato = value;
			}

			return true;
		});

		setData({
			...data,
			contatos: contatos,
		});
	};

	const handleChangeAddress = name => async event => {
		setErrors([]);

		setData({
			...data,
			endereco: {
				...data.endereco,
				[name]: event.target.value,
			},
		});

		if (name === 'cep' && event.target.value.length < 1) {
			setData({
				...data,
				endereco: {
					...data.endereco,
					cep: '',
					logradouro: '',
					cidade: '',
					estado: '',
					complemento: '',
				},
			});
		} else if (name === 'cep' && event.target.value.length === 9) {
			var value = event.target.value;
			var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + value);

			if (res.data.success === false || res.data.endereco.erro) {
				setData({
					...data,
					endereco: {
						...data.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
					},
				});
				setErrors(['CEP não encontrado']);
				return false;
			}

			setData({
				...data,
				endereco: {
					...data.endereco,
					cep: Mask.OnlyNumber(res.data.endereco.cep),
					logradouro: res.data.endereco.logradouro,
					cidade: res.data.endereco.localidade,
					estado: res.data.endereco.uf,
					complemento: '',
					bairro: res.data.endereco.bairro,
				},
			});
		}
	};
	const handleSubmitForm = async () => {
		setErrors([]);
		setLoading(true);

		if (data.documentos[0].documento.length < 10) {
			dispatch(addMessage({ type: 'error', message: 'Por favor, Preencha o campo CPF / CNPJ' }));
			setLoading(false);
		} else {
			try {
				ValidatorForm(validateFields, data);
			} catch (erro) {
				dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
				setLoading(false);
				setOnError(true);
				return false;
			}

			if (data.usuario.grupoId === 4 && data.estabelecimentosSelecionados.length === 0) {
				dispatch(addMessage({ type: 'error', message: 'Selecione pelo menos 1 estabelecimento.' }));
				setLoading(false);
				setOnError(true);
				return false;
			}

			data.estabelecimentos = data.estabelecimentosSelecionados.map(a => a.id);
			data.usuario.canSplit = canSplitChecked;

			var res = '';
			if (typeof data.usuarioId === 'string') {
				res = await axios.put(config.get('apiUrl') + 'usuarios/' + data.usuarioId, data, {
					headers: { Authorization: `Bearer ${token}` },
				});
			} else {
				res = await axios.post(config.get('apiUrl') + 'usuarios', data, {
					headers: { Authorization: `Bearer ${token}` },
				});
			}

			setLoading(false);
			if (res.data.error && res.data.error.length) {
				setErrors([res.data.error]);
			} else if (res.data.errors && res.data.errors.length) {
				setErrors(res.data.errors);
			} else if (res.data.success === false && res.data.message.length > 1) {
				setErrors([res.data.message]);
			}

			if (res.data.success === true) {
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
				history.push('/usuarios');
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
			}
		}
	};

	const openECs = () => {
		setOpenEstabelecimentos(true);
	};

	const toggleEC = e => {
		const value = e.target.value;

		if (e.target.checked) {
			const ec = ecs.find(a => a.id === value);

			setData({
				...data,
				estabelecimentosSelecionados: [...data.estabelecimentosSelecionados, ec],
			});
		} else {
			const ecIndex = data.estabelecimentosSelecionados.findIndex(a => a.id === value);
			data.estabelecimentosSelecionados.splice(ecIndex, 1);

			setData({
				...data,
				estabelecimentosSelecionados: data.estabelecimentosSelecionados,
			});
		}
	};

	const selecionarECs = () => {
		setOpenEstabelecimentos(false);
	};

	const removerEstabelecimento = id => {
		const ecIndex = data.estabelecimentosSelecionados.findIndex(a => a.id === id);
		data.estabelecimentosSelecionados.splice(ecIndex, 1);

		setData({
			...data,
			estabelecimentosSelecionados: data.estabelecimentosSelecionados,
		});
	};
	const estabelecimento = useSelector(state => state.auth.estabelecimento);

	const isElo7 = estabelecimento.marketplace.id === 129;

	var showEstabelecimentos = false;
	const estabelecimentoOptions = [];
	// const estabelecimentoOptions = usuario.estabelecimentos.map((value, key) => {
	// 	return {
	// 		id: value.id,
	// 		name: value.nomeFantasia,
	// 	};
	// }); tava bugado, e nem sei se isso realmente é utilizado

	if (estabelecimentoOptions.length > 1) {
		showEstabelecimentos = true;
	}

	const menuItems = [
		<MenuItem value="" key="menuItem0">
			<em>Grupo</em>
		</MenuItem>,
		<MenuItem value={2} key="menuItem2">
			Gerencial
		</MenuItem>,
		<MenuItem value={3} key="menuItem3">
			Básico
		</MenuItem>,
	];

	if (isAdmin) {
		menuItems.push(
			<MenuItem value={10} key="menuItem10">
				Moderador
			</MenuItem>
		);
		menuItems.push(
			<MenuItem value={7} key="menuItem7">
				Backoffice
			</MenuItem>
		);
		menuItems.push(
			<MenuItem value={5} key="menuItem5">
				Representante
			</MenuItem>
		);
		menuItems.push(
			<MenuItem value={8} key="menuItem8">
				Representante Básico
			</MenuItem>
		);
		menuItems.push(
			<MenuItem value={11} key="menuItem11">
				Perfil de Risco
			</MenuItem>
		);
		menuItems.push(
			<MenuItem value={12} key="menuItem12">
				Atendimento
			</MenuItem>
		);
		if (isElo7) {
			menuItems.push(
				<MenuItem value={13} key="menuItem13">
					Financeiro Elo 7
				</MenuItem>
			);
			menuItems.push(
				<MenuItem value={14} key="menuItem14">
					Financeiro Gerencial
				</MenuItem>
			);
			menuItems.push(
				<MenuItem value={15} key="menuItem15">
					Risco Gerencial
				</MenuItem>
			);
		}
	} else if (operarMarketplace) {
		menuItems.push(
			<MenuItem value={7} key="menuItem7">
				Backoffice
			</MenuItem>
		);
		menuItems.push(
			<MenuItem value={5} key="menuItem5">
				Representante
			</MenuItem>
		);
		menuItems.push(
			<MenuItem value={8} key="menuItem8">
				Representante Básico
			</MenuItem>
		);

		menuItems.push(
			<MenuItem value={12} key="menuItem12">
				Atendimento
			</MenuItem>
		);
		menuItems.push(
			<MenuItem value={13} key="menuItem13">
				Financeiro Elo7
			</MenuItem>
		);
	}
	return (
		<Grid>
			<ZTitle title={pageTitle} />
			<ZErrors errors={errors} />

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper style={{ padding: 15, marginBottom: 10, textAlign: 'center' }}>
						<FormControl style={{ width: 200 }}>
							<InputLabel shrink>Grupo</InputLabel>
							<Select value={data.usuario.grupoId} onChange={grupoIdChange} displayEmpty>
								{menuItems.map(a => a)}
							</Select>
						</FormControl>
					</Paper>

					{data.usuario.grupoId !== '' && (
						<>
							{[5, 8].includes(data.usuario.grupoId) && (
								<Paper style={{ padding: 15, marginBottom: 10, textAlign: 'center' }}>
									<Grid container justifyContent="center" spacing={3}>
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<ZSelect
												options={[{ id: null, name: 'Nenhum' }, ...representantes]}
												label="Representante Master"
												value={data.usuario.parentId}
												onBlur={handleChange('parentId')}
											/>
										</Grid>
									</Grid>
								</Paper>
							)}
							<Paper style={{ padding: 15 }}>
								<Grid container spacing={3}>
									{showEstabelecimentos && (
										<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 45 }}>
											<ZSelect
												options={estabelecimentoOptions}
												label="Estabelecimento"
												width="100%"
												value={data.estabelecimentoId}
												onBlur={handleChange('estabelecimentoId')}
											/>
										</Grid>
									)}
									<Grid item xs={12} style={{ marginBottom: 20 }}>
										<Typography variant="h6" gutterBottom>
											Dados Pessoais
										</Typography>
										<Divider />
									</Grid>
									<Grid item xs={12} sm={6} md={4} lg={3}>
										<ZText
											onError={onError}
											required={true}
											label="Nome"
											value={data.usuario.nome}
											onBlur={handleChange('nome')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4} lg={3}>
										<ZText
											onError={onError}
											required={true}
											email={true}
											label="E-mail"
											value={data.usuario.email}
											onBlur={handleChange('email')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4} lg={3}>
										<ZText
											onError={onError}
											required={true}
											label="Celular"
											mask="(99) 9 9999-9999"
											value={data.contatos[0].valorContato}
											onBlur={handleChangeContato(2, data.contatos[0].id)}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4} lg={3}>
										<ZText
											onError={onError}
											label="Telefone"
											mask="(99) 9999-9999"
											value={data.contatos[1].valorContato}
											onBlur={handleChangeContato(1, data.contatos[1].id)}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4} lg={3}>
										{/* <ZText
											onError={onError}
											required={true}
											mask={data.documentos[0].documento.length <= 11 ? "999.999.999-99" : "99999999999"}
											label="CPF / CNPJ"
											value={data.documentos[0].documento.length <= 11}
											onBlur={handleChangeDocumento(2, data.documentos[0].id)}
										/> */}
										<TextField
											className={classes.fullWidth}
											error={errors.cpf}
											label="CPF / CNPJ"
											value={
												data.documentos[0].documento.length <= 11
													? Mask.Cpf(data.documentos[0].documento)
													: Mask.Cnpj(data.documentos[0].documento)
											}
											onChange={handleChangeDocumento(2, data.documentos[0].id)}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={4} lg={3}>
										<ZSelect
											required={true}
											onError={onError}
											options={[
												{ id: 'F', name: 'Feminino' },
												{ id: 'M', name: 'Masculino' },
											]}
											label="Sexo"
											value={data.usuario.sexo}
											onBlur={handleChange('sexo')}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={4} lg={2}>
										<ZText
											onError={onError}
											required={true}
											label="Data Nascimento"
											mask="99/99/9999"
											value={data.usuario.dataNascimento2}
											onBlur={handleChange('dataNascimento2')}
										/>
									</Grid>
								</Grid>

								{[4].includes(data.usuario.grupoId) && (
									<Grid container spacing={3} style={{ marginTop: 40 }}>
										<Grid item xs={12} style={{ marginBottom: 20 }}>
											<Typography variant="h6" gutterBottom>
												Estabelecimentos
												<Fab
													color="primary"
													onClick={openECs}
													style={{ marginLeft: 10, width: 30, height: 30, minHeight: 30 }}
													aria-label="Add"
												>
													<AddIcon />
												</Fab>
											</Typography>
											<Divider />
											<div>
												{data.estabelecimentosSelecionados.map((ec, i) => (
													<Chip
														key={i}
														size="small"
														style={{ margin: 4 }}
														onDelete={() => removerEstabelecimento(ec.id)}
														label={ec.nomeFantasia}
													/>
												))}
											</div>

											<Dialog
												disableBackdropClick
												disableEscapeKeyDown
												open={openEstabelecimentos}
												onClose={() => setOpenEstabelecimentos(false)}
											>
												<DialogTitle>Selecione os Estabelecimentos</DialogTitle>
												<DialogContent>
													<Grid
														container
														spacing={2}
														style={{ maxHeight: 300, overflowY: 'scroll' }}
													>
														<Grid item xs={12}>
															<ZFiltros
																onChange={data => {
																	setECsFilters({
																		...ecsFilters,
																		omniEstabelecimento: data.omni,
																	});
																}}
																omni={{
																	default: ecsFilters.omniEstabelecimento,
																	placeholder: 'Pesquisa por Nome ou CPF/CNPJ',
																}}
															/>
														</Grid>

														{ecsLoading ? (
															<ZLoader
																height={200}
																message="Carregando Estabelecimentos"
															/>
														) : (
															<>
																{/* <Grid item xs={12}>
																	<div>
																		<FormControlLabel
																			value="end"
																			control={
																				<Switch
																					color="primary"
																					onChange={e => {
																						if (e.target.checked) {
																							setData({
																								...data,
																								estabelecimentosSelecionados: ecs.slice(
																									0
																								),
																							});
																						} else {
																							setData({
																								...data,
																								estabelecimentosSelecionados: [],
																							});
																						}
																					}}
																				/>
																			}
																			label="Selecionar todos"
																			labelPlacement="end"
																		/>
																	</div>
																</Grid> */}

																{ecs.map((ec, i) => (
																	<Grid item key={i} xs={12} sm={6}>
																		<FormControlLabel
																			className={classes.ecs}
																			value="end"
																			control={
																				<Switch
																					checked={
																						data.estabelecimentosSelecionados.find(
																							a => +a.id === +ec.id
																						) !== undefined
																					}
																					onChange={toggleEC}
																					value={ec.id}
																					color="primary"
																				/>
																			}
																			label={
																				ec.razaoSocial
																					? ec.razaoSocial
																					: ec.nomeFantasia
																			}
																			labelPlacement="end"
																		/>
																	</Grid>
																))}
															</>
														)}
													</Grid>

													<Grid container spacing={2}>
														<Grid
															item
															xs={12}
															style={{ paddingTop: 10, textAlign: 'center' }}
														>
															<Button
																variant="contained"
																onClick={() => setOpenEstabelecimentos(false)}
																style={{ marginRight: 10 }}
															>
																Cancelar
															</Button>
															<Button
																variant="contained"
																color="primary"
																onClick={selecionarECs}
															>
																Confirmar
															</Button>
														</Grid>
													</Grid>
												</DialogContent>
											</Dialog>
										</Grid>
									</Grid>
								)}

								<Grid container spacing={3} style={{ marginTop: 40 }}>
									<Grid item xs={12} style={{ marginBottom: 20 }}>
										<Typography variant="h6" gutterBottom>
											Endereço
										</Typography>
										<Divider />
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<ZText
											onError={onError}
											required={true}
											label="CEP"
											mask="99999-999"
											value={data.endereco.cep}
											onBlur={handleChangeAddress('cep')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={1}>
										<ZText
											onError={onError}
											required={true}
											label="Número"
											value={data.endereco.numero}
											onBlur={handleChangeAddress('numero')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										<ZText
											// inputProps={{ readOnly: true }}
											label="Logradouro"
											value={data.endereco.logradouro}
											onBlur={handleChangeAddress('logradouro')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<ZText
											// inputProps={{ readOnly: true }}
											label="Bairro"
											value={data.endereco.bairro}
											onBlur={handleChangeAddress('bairro')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<ZText
											// inputProps={{ readOnly: true }}
											label="Cidade"
											value={data.endereco.cidade}
											onBlur={handleChangeAddress('cidade')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={1}>
										<ZText
											// inputProps={{ readOnly: true }}
											label="Estado"
											value={data.endereco.estado}
											onBlur={handleChangeAddress('estado')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										<ZText
											label="Complemento"
											value={data.endereco.complemento}
											onBlur={handleChangeAddress('complemento')}
										/>
									</Grid>
									{!representante && (
										<Grid item xs={12} style={{ marginBottom: 10 }}>
											<FormControlLabel
												value="end"
												control={
													<Checkbox
														checked={canSplitChecked}
														onChange={({ target }) => setCanSplitChecked(target.checked)}
														color="primary"
													/>
												}
												label="Configura Split"
												labelPlacement="end"
											/>
										</Grid>
									)}
								</Grid>
								<Grid
									container
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
									style={{ marginTop: 25 }}
								>
									<ZSaveButton
										onClick={() => handleSubmitForm()}
										color={'primary'}
										align="right"
										label={'Salvar'}
										loading={loading}
									/>
								</Grid>
							</Paper>
						</>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
