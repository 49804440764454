/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import axios from 'axios';
// import cryptoJS from 'crypto-js';
import configuration from 'react-global-configuration';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

import ZLoader from './system/ZLoader';
import ZSplashScreen from './system/ZSplashScreen';
import Login from './Usuarios/Login';
import MainSystem from './Layout/MainSystem';
import CadastrarEstabelecimento, { CadastroSuccess } from './Estabelecimentos/CadastrarEstabelecimento';
import Cadastrar from './Usuarios/Cadastrar';
import AssinarPlano from './Planos/AssinarPlano';
import EsqueciMinhaSenha from './Usuarios/EsqueciMinhaSenha';
import PreCadastrarEC from './Marketplace/PreCadastrarEC';
import VendasDetalhes from './Vendas/VendasDetalhes';
// import PagarPreVenda from './PreVenda/PagarPreVenda';
import PagarVendaDigitada from './VendaDigitada/PagarVendaDigitada';
import PagarVendaDigitadaMultiplo from './VendaDigitada/PagarVendaDigitadaMultiplo';
import VisualizarBoleto from './Vendas/VisualizarBoleto';
import ImprimirBoletos from './PortalCliente/ImprimirBoletos';
import MeusBoletos from './PortalCliente/MeusBoletos';
import BoletoPublico from './Vendas/BoletoPublico';
import PagarAssinaturaPix from './Planos/PagarAssinaturaPix';
import CarnePrint from './Carnes/Print';
import { useDispatch, useSelector } from 'react-redux';
import { Isset } from '../utils';
import { checkLogin, checkLoginIsValid } from '../store/auth';
import { getConfig, setSystemConfig, getUserParams } from '../store/system';
import ZWarning from './system/ZWarning';
import { useHistory } from 'react-router-dom';
import RecuperarSenha from './Usuarios/RecuperarSenha';

const changeFavicon = src => {
	const link = document.createElement('link');
	const oldLink = document.getElementById('dynamic-favicon');
	link.id = 'dynamic-favicon';
	link.rel = 'shortcut icon';
	link.href = src;
	if (oldLink) {
		document.head.removeChild(oldLink);
	}
	document.head.appendChild(link);
};

// console.log(process.env);
export default props => {
	const dispatch = useDispatch();
	const logado = useSelector(state => state.auth.logado);
	const usuario = useSelector(state => state.auth.usuario);
	let color = useSelector(state => state.system.color);
	let systemsConfig = useSelector(state => state.system);
	let colorSystem = systemsConfig.config?.color ? systemsConfig.config.color : systemsConfig.color;

	const systemConfig = useSelector(state => state.system.config);
	const history = useHistory();
	const location = history.location;
	const themeSystem = createTheme({
		typography: {
			fontFamily: ['"Exo 2"', 'sans-serif'].join(','),
		},
		palette: {
			primary: {
				main: color,
			},
		},
	});
	const theme = createTheme({
		typography: {
			fontFamily: ['"Exo 2"', 'sans-serif'].join(','),
		},
		palette: {
			primary: {
				main: colorSystem,
			},
		},
	});

	if (usuario.id) {
		dispatch(checkLoginIsValid());
	}

	const [loadingConfig, setLoadingConfig] = useState(true);
	const [loading, setLoading] = useState(true);
	const [systemOff, setSystemOff] = useState(false);

	useEffect(() => {
		const getSystemConfig = async () => {
			let hostName = window.location.hostname;

			if (hostName === 'localhost' && configuration.get('hostName')) {
				hostName = configuration.get('hostName');
			}

			const res = await axios.get(`${configuration.get('apiUrl')}system_config/${hostName}`);

			if (res.data.success) {
				dispatch(setSystemConfig(res.data.config));
			} else {
				setSystemOff(true);
			}
			setLoadingConfig(false);
		};

		getSystemConfig();
	}, []);

	useEffect(() => {
		if (!loadingConfig) {
			const login = async () => {
				const login = await dispatch(checkLogin());
				if (Isset(() => login.usuario.estabelecimento.id)) {
					await dispatch(getConfig(login.usuario.estabelecimento.id));
				}
				return login;
			};
			login().then(_ => {
				setLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingConfig]);

	useEffect(() => {
		window.document.title = systemConfig.nome;
		changeFavicon(systemConfig.faviconUrl);
	}, [systemConfig]);

	useEffect(() => {
		if (logado) {
			dispatch(getUserParams(usuario));
		}
	}, [logado]);

	if (systemOff) {
		return <ZWarning message="Sistema em manutenção" />;
	}

	if (loadingConfig) {
		return <ZLoader message="Carregando Configurações" />;
	}

	if (loading) {
		return <ZSplashScreen />;
	}

	// var encrypted = cryptoJS.AES.encrypt('15', 'planoZoopZSPay').toString();

	// console.log('encrypted: ' + encrypted);
	// console.log('decripted: ' + cryptoJS.AES.decrypt(encrypted, 'planoZoopZSPay').toString(cryptoJS.enc.Utf8));

	const regAssinar = new RegExp('/plano/[0-9]{0,}/assinar');
	const regAssinarSekron = new RegExp('/plano/[0-9]{0,}/assinar/sekron');
	const urlDeAssinatura =
		regAssinar.exec(location.pathname) || regAssinarSekron.exec(location.pathname) ? true : false;
	if (urlDeAssinatura) {
		const isSekron = regAssinarSekron.exec(location.pathname) ? true : false;
		let numberPattern = /\d+/g;
		let planoId = location.pathname.match(numberPattern);

		return <AssinarPlano planoId={planoId[0]} isSekron={isSekron} />;
	}

	const regAtualizarCartaoDaAssinatura = new RegExp('/assinatura/atualizar/.*');
	if (regAtualizarCartaoDaAssinatura.exec(location.pathname)) {
		let subscriptionId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
		return <AssinarPlano subscriptionId={subscriptionId} />;
	}

	const regLinkPagamentoMonetiza = new RegExp('/lp-multiple/[a-zA-Z0-9]{0,}');
	if (regLinkPagamentoMonetiza.exec(location.pathname)) {
		// let numberPattern = /\d+/g;
		// let token = location.pathname.match(numberPattern);
		let token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
		return <PagarVendaDigitadaMultiplo token={token} />;
	}

	const regPreVenda = new RegExp('/lp/[a-zA-Z0-9]{0,}');
	const regPreVenda2 = new RegExp('/venda/token/[a-zA-Z0-9]{0,}');
	if (regPreVenda.exec(location.pathname) || regPreVenda2.exec(location.pathname)) {
		// let numberPattern = /\d+/g;
		// let token = location.pathname.match(numberPattern);
		let token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
		return <PagarVendaDigitada token={token} />;
	}

	const regAssinaturaPix = new RegExp('/assinar/.*');
	if (regAssinaturaPix.exec(location.pathname)) {
		let data = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

		return <PagarAssinaturaPix data={data} />;
	}

	const regPagarBoleto = new RegExp('/vendas/pagar/[0-9]{0,}');
	// const regPagarBoleto2 = new RegExp('/venda/token/[a-zA-Z0-9]{0,}');
	if (regPagarBoleto.exec(location.pathname)) {
		// let numberPattern = /\d+/g;
		// let token = location.pathname.match(numberPattern);
		let pedidoId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
		return <PagarVendaDigitada pedidoId={pedidoId} />;
	}

	const imprimirCarne = new RegExp('/carnes/[0-9]{0,}/imprimir');
	if (imprimirCarne.exec(location.pathname)) {
		// let [carneId] = location.pathname.substring(location.pathname.lastIndexOf('carnes/') + 1).split('/');
		let carneId = location.pathname.match(/\d+/)[0];
		return <CarnePrint carneId={carneId} />;
	}

	if (location.pathname === '/cadastrar') {
		return <Cadastrar />;
	}

	// Visualizar Boleto
	const regPreVenda3 = new RegExp('/vendas/[a-zA-Z0-9]{0,}/boleto');
	if (regPreVenda3.exec(location.pathname)) {
		let numberPattern = /\d+/g;
		let pedidoId = location.pathname.match(numberPattern);
		return <VisualizarBoleto pedidoId={pedidoId[0]} />;
	}

	// Imprimir Boleto
	const imprimirBoleto = new RegExp('/imprimir-boletos');
	if (imprimirBoleto.exec(location.pathname)) {
		let boletos = location.state.boletos;
		return <ImprimirBoletos boletos={boletos} />;
	}

	if (location.pathname === '/cadastrar/estabelecimento') {
		return (
			<ThemeProvider theme={theme}>
				<CadastrarEstabelecimento />
			</ThemeProvider>
		);
	}

	if (location.pathname === '/cadastro/sucesso') {
		return <CadastroSuccess />;
	}

	const regDetalheVenda = new RegExp('/vendas/detalhe/[0-9]{0,}');
	if (regDetalheVenda.exec(location.pathname)) {
		let numberPattern = /\d+/g;
		let vendaId = location.pathname.match(numberPattern);

		return <VendasDetalhes inUrlPublica={true} vendaId={vendaId[0]} />;
	}

	const regDetalheVendaPublico = new RegExp('/vendas/detalhes/[0-9]{0,}/publico');
	if (regDetalheVendaPublico.exec(location.pathname)) {
		let numberPattern = /\d+/g;
		let vendaId = location.pathname.match(numberPattern);

		return <VendasDetalhes inUrlPublica={true} vendaId={vendaId[0]} />;
	}

	const regDetalheBoletoPublico = new RegExp('/boleto/[0-9]{0,}/publico');
	if (regDetalheBoletoPublico.exec(location.pathname)) {
		let numberPattern = /\d+/g;
		let vendaId = location.pathname.match(numberPattern);

		return <BoletoPublico vendaId={vendaId[0]} />;
	}

	if (location.pathname === '/consultar-boleto') {
		return <MeusBoletos />;
	}

	const regListagemBoletos = new RegExp('/listagem-boletos/[0-9]{0,}');
	if (regListagemBoletos.exec(location.pathname)) {
		return <MeusBoletos />;
		// const documento = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

		// return <ListagemBoletos documento={documento} />;
	}

	if (location.pathname === '/esqueci-minha-senha') {
		return <EsqueciMinhaSenha location={location} />;
	}

	if (location.pathname === '/recuperar-senha') {
		return <RecuperarSenha location={location} />;
	}

	if (location.pathname === '/pre-cadastro-ec') {
		return <PreCadastrarEC />;
	}

	if (!logado) {
		return (
			<ThemeProvider theme={theme}>
				<Login location={history} />
			</ThemeProvider>
		);
	}

	return (
		<ThemeProvider theme={themeSystem}>
			<MainSystem />
		</ThemeProvider>
	);
};
