/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Paper, Divider, Button, Tooltip } from '@material-ui/core';
import { FiPrinter } from 'react-icons/fi';
import { Formatter } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import config from 'react-global-configuration';
import format from 'date-fns/format';
import ZLoader from '../system/ZLoader';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 17,
		margin: 4,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	valor: {
		fontSize: 18,
		fontWeight: 700,
	},
	paper: {
		padding: 10,
		height: '100%',
	},
	pedidoRow: {
		borderBottom: 'solid 1px #abc',
	},
	notFound: {
		textAlign: 'center',
		fontSize: 20,
		fontWeight: 'bold',
		fontVariant: 'small-caps',
	},
	printButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
}));

export default props => {
	// const { match } = useReactRouter();
	const match = useRouteMatch();
	const { transferenciaId } = match.params;

	const token = useSelector(store => store.auth.token);

	const classes = useStyles();

	const [transferencia, setTransferencia] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getTransferencia = async () => {
			setLoading(true);
			const res = await axios.get(`${config.get('apiUrl')}transferencias/${transferenciaId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			const { transferencia, success } = res.data;

			if (success) {
				setTransferencia(transferencia);
			}
			setLoading(false);
		};

		getTransferencia();
	}, []);

	return (
		<div className={classes.transferencia}>
			{loading ? (
				<ZLoader height={200} />
			) : (
				<>
					{transferencia === null ? (
						<div className={classes.notFound}>Transferência não encontrada.</div>
					) : (
						<React.Fragment>
							<Grid container justifyContent="space-between" alignItems="center">
								<div className={classes.header}>Transferência</div>

								<Tooltip title="Imprimir" arial-label="imprimir">
									<Button className={classes.printButton} onClick={() => window.print()}>
										<FiPrinter size={16} />
									</Button>
								</Tooltip>
							</Grid>
							<Grid id="section-to-print" container spacing={2} direction="row" justifyContent="center">
								<Grid item xs={12} md={10}>
									<Paper className={classes.paper}>
										<Grid container>
											<Grid item xs={12}>
												<div className={classes.field}>
													<div className={classes.label}>
														Valor{' '}
														<span className={classes.valor}>
															{Formatter.Real(transferencia.valor)}
														</span>
													</div>
												</div>
											</Grid>
											<Divider style={{ width: '100%' }} />
											<Grid item xs={12} sm={3}>
												<div className={classes.field}>
													<div className={classes.label}>Data</div>
													<div>{format(transferencia.created, 'DD/MM/YYYY HH:mm:ss')}</div>
												</div>
											</Grid>
											<Grid item xs={12} sm={3}>
												<div className={classes.field}>
													<div className={classes.label}>Tipo de Transferência</div>
													<div>{transferencia.tipo}</div>
												</div>
											</Grid>
											<Grid item xs={12} sm={3}>
												<div className={classes.field}>
													<div className={classes.label}>Status</div>
													<div>{transferencia.status}</div>
												</div>
											</Grid>
											<Grid item xs={12} sm={3}>
												<div className={classes.field}>
													<div className={classes.label}>Descrição</div>
													<div>{transferencia.descricao}</div>
												</div>
											</Grid>
										</Grid>
									</Paper>
								</Grid>

								<Grid item xs={12} md={10}>
									<Grid
										container
										spacing={2}
										direction="row"
										justifyContent="center"
										alignItems="stretch"
									>
										<Grid item xs={12} sm={6}>
											<Paper className={classes.paper}>
												<div className={classes.subheader}>Conta de Origem</div>

												<Grid container>
													<Grid item xs={12} md={6}>
														<div className={classes.field}>
															<div className={classes.label}>Nome</div>
															<div>{transferencia.from.nome}</div>
														</div>
													</Grid>
													<Grid item xs={12} md={6}>
														<div className={classes.field}>
															<div className={classes.label}>
																{transferencia.from.documento.length <= 11
																	? 'CPF'
																	: 'CNPJ'}
															</div>
															<div>
																{transferencia.from.documento.length <= 11
																	? Formatter.Cpf(transferencia.from.documento)
																	: Formatter.Cnpj(transferencia.from.documento)}
															</div>
														</div>
													</Grid>
												</Grid>
												<Grid item xs={12} md={6}>
													<div className={classes.field}>
														<div className={classes.label}>E-mail</div>
														<div>{transferencia.from.email}</div>
													</div>
												</Grid>
											</Paper>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Paper className={classes.paper}>
												<div className={classes.subheader}>Conta de Destino</div>

												{transferencia.contaBancaria !== null ? (
													<Grid container>
														<Grid item xs={12} sm={4}>
															<div className={classes.field}>
																<div className={classes.label}>Nome</div>
																<div>{transferencia.contaBancaria.nome_titular}</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={8}>
															<div className={classes.field}>
																<div className={classes.label}>Banco</div>
																<div>{transferencia.contaBancaria.banco.nome}</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={4}>
															<div className={classes.field}>
																<div className={classes.label}>Tipo de Conta</div>
																<div>
																	{
																		transferencia.contaBancaria.tipo_conta_bancaria
																			.titulo
																	}
																</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={4}>
															<div className={classes.field}>
																<div className={classes.label}>Agência</div>
																<div>{transferencia.contaBancaria.agencia}</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={4}>
															<div className={classes.field}>
																<div className={classes.label}>Conta</div>
																<div>{transferencia.contaBancaria.conta}</div>
															</div>
														</Grid>
													</Grid>
												) : (
													<Grid container>
														<Grid item xs={12} md={4}>
															<div className={classes.field}>
																<div className={classes.label}>Nome</div>
																<div>{transferencia.to.nome}</div>
															</div>
														</Grid>
														<Grid item xs={12} md={4}>
															<div className={classes.field}>
																<div className={classes.label}>
																	{transferencia.to.documento.length <= 11
																		? 'CPF'
																		: 'CNPJ'}
																</div>
																<div>
																	{transferencia.to.documento.length <= 11
																		? Formatter.Cpf(transferencia.to.documento)
																		: Formatter.Cnpj(transferencia.to.documento)}
																</div>
															</div>
														</Grid>
														<Grid item xs={12} md={4}>
															<div className={classes.field}>
																<div className={classes.label}>E-mail</div>
																<div>{transferencia.to.email}</div>
															</div>
														</Grid>
													</Grid>
												)}
											</Paper>
										</Grid>
									</Grid>
								</Grid>

								{transferencia.pedidos.length > 0 && (
									<Grid item xs={12} md={10}>
										<Paper className={classes.paper}>
											<div className={classes.subheader}>Pedidos</div>

											<Grid container spacing={2}>
												<Grid item xs={3}>
													<div className={classes.field}>
														<div className={classes.label}>Data</div>
													</div>
												</Grid>
												<Grid item xs={3}>
													<div className={classes.field}>
														<div className={classes.label}>Cliente</div>
													</div>
												</Grid>
												<Grid item xs={3}>
													<div className={classes.field}>
														<div className={classes.label}>Estabelecimento</div>
													</div>
												</Grid>
												<Grid item xs>
													<div className={classes.field}>
														<div className={classes.label}>Parcela</div>
													</div>
												</Grid>
												<Grid item xs>
													<div className={classes.field}>
														<div className={classes.label}>Valor</div>
													</div>
												</Grid>
											</Grid>

											{transferencia.pedidos.map(pedido => (
												<Grid
													key={pedido.id}
													container
													spacing={2}
													className={classes.pedidoRow}
												>
													<Grid item xs={3}>
														{format(pedido.data, 'DD/MM/YYYY HH:mm:ss')}
													</Grid>
													<Grid item xs={3}>
														{pedido.cliente}
													</Grid>
													<Grid item xs={3}>
														{pedido.estabelecimento}
													</Grid>
													<Grid item xs>
														{`${pedido.parcela}/${pedido.totalParcelas}`}
													</Grid>
													<Grid item xs>
														{Formatter.Real(pedido.valor)}
													</Grid>
												</Grid>
											))}
										</Paper>
									</Grid>
								)}
							</Grid>
						</React.Fragment>
					)}
				</>
			)}
		</div>
	);
};
